<div class="loading-container" *ngIf="!retrievedUsers || gettingEvents">
    <div class="content">
        <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    </div>
</div>

<div class="row" style="padding-bottom: 10px;">
    <div class="col-sm-9">         
        <app-filter-section [forAnalytics]="false" [events]="events" (onFilter)="onFilter($event)" (initialLoad)="initialLoadFromFilter($event)"></app-filter-section>
    </div>
    <div class="col-sm-3">
        <div class="right">
            <mat-slide-toggle *ngIf="canSeeWeeklyListSlider" [(ngModel)]="weeklyListView" class="gridbtn" (change)="toggleListViewChange($event)">List View</mat-slide-toggle>

            <!-- <mat-slide-toggle [(ngModel)]="bulkEdit" class="gridbtn" (change)="toggleBulkEditChange($event)">Bulk Edit</mat-slide-toggle>

            <button mat-raised-button class="gridbtn" *ngIf="bulkEdit && selectedEvents.length > 0" (click)="bulkEditEvents()">
                <mat-icon>edit_note</mat-icon>
            </button> -->

            <app-bulk-edit-btns
                [display]="bulkEdit"
                [(saving)]="bulkEditSaving"
                [bulkEditType]="bulkEditType"
                [events]="selectedEvents"
                [allEventSubs]="eventSubs"
                (complete)="completeBulkEdit()">
            </app-bulk-edit-btns>

            <button mat-raised-button class="gridbtn" *ngIf="canSeeHarvestAdd || canSeeGroupAdd || canSeeAddEventBtn" 
                [matMenuTriggerFor]="addmenu" aria-label="Example icon-button with a menu">
                <mat-icon>add</mat-icon>
            </button>
            <mat-menu #addmenu="matMenu">
                <button mat-menu-item *ngIf="canSeeHarvestAdd" (click)="addHarvest()">
                    <mat-icon fontSet="material-symbols-outlined">potted_plant</mat-icon>
                    <span>Harvest</span>
                </button>
                <button mat-menu-item *ngIf="canSeeGroupAdd" (click)="addGroup()">
                    <mat-icon>splitscreen</mat-icon>
                    <span>Task Group</span>
                </button>
                <button mat-menu-item *ngIf="canSeeAddEventBtn" [matMenuTriggerFor]="addeventmenu">
                    <mat-icon>event</mat-icon>
                    <span>Event</span>
                </button>
                
                <!-- Apply Workflow Button  -->
                <app-apply-workflow-btn [showApplyToGroupButton]="true"></app-apply-workflow-btn>

            </mat-menu>

            <mat-menu #addeventmenu="matMenu">
                <button mat-menu-item (click)="addEvent(null)">
                    <mat-icon>add</mat-icon>
                    <span>Event</span>
                </button>
                <button mat-menu-item (click)="addEventFromLibrary()">
                    <mat-icon>add_task</mat-icon>
                    <span>Add From Library</span>
                </button>
            </mat-menu>

            <app-dutchie-btn (addedHarvests)="addedHarvests($event)"></app-dutchie-btn>

            <button mat-raised-button class="gridbtn" *ngIf="canSeeMassUserAssign || canSeeHarvestGroupDelete" 
                [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item *ngIf="canCompleteEvent || canSeeMassUserAssign || canSeeHarvestGroupDelete" [matMenuTriggerFor]="bulkeditmenu" [disabled]="bulkEdit || bulkEditSaving">
                    <mat-icon>edit_note</mat-icon>
                    <span>Bulk Edit</span>
                </button>
                <button mat-menu-item *ngIf="canSeeMassUserAssign" (click)="usersMassAssign()">
                    <mat-icon>group_add</mat-icon>
                    <span>Mass assign Users</span>
                </button>
                <app-harvest-shift-btn [dropDownMenu]="true"></app-harvest-shift-btn>
                <button mat-menu-item *ngIf="canSeeHarvestGroupDelete" (click)="harvestGroupDelete()">
                    <mat-icon>delete</mat-icon>
                    <span>Delete Harvests and Task Groups</span>
                </button>
            </mat-menu>

            <mat-menu #bulkeditmenu="matMenu">
                <button mat-menu-item *ngIf="canCompleteEvent" [matMenuTriggerFor]="bulkeditcompletemenu">
                    <mat-icon>task_alt</mat-icon>
                    <span>Complete/Incomplete</span>
                </button>
                <button mat-menu-item *ngIf="canSeeMassUserAssign" (click)="toggleBulkEditChange('assign')">
                    <mat-icon>assignment_add</mat-icon>
                    <span>Assign/Unassign</span>
                </button>
                <button mat-menu-item *ngIf="canSeeHarvestGroupDelete" (click)="toggleBulkEditChange('delete')">
                    <mat-icon>delete</mat-icon>
                    <span>Delete</span>
                </button>
            </mat-menu>

            <mat-menu #bulkeditcompletemenu="matMenu">
                <button mat-menu-item (click)="toggleBulkEditChange('complete')">
                    <mat-icon>check</mat-icon>
                    <span>Complete</span>
                </button>
                <button mat-menu-item (click)="toggleBulkEditChange('incomplete')">
                    <mat-icon>close</mat-icon>
                    <span>Incomplete</span>
                </button>
            </mat-menu>
        </div>
    </div>
</div>
<!-- <div class="row" *ngIf="canSeeAddEventBtn || canSeeWeeklyListSlider">
    <div class="col-12" style="padding-bottom: 10px;">
        <div style="float: right">
            <mat-slide-toggle *ngIf="canSeeWeeklyListSlider" [(ngModel)]="weeklyListView" class="gridbtn" (change)="toggleListViewChange($event)">List View</mat-slide-toggle>
            <button *ngIf="canSeeAddEventBtn" class="gridbtn" (click)="addEvent()" mat-raised-button title="Add Event">
                <mat-icon aria-hidden="false" aria-label="Add Event">add</mat-icon>
            </button>
        </div>
    </div>
</div> -->
<div class="row calendar-container">
    <div class="col-12">
        <full-calendar #calendar [options]="calendarOptions" (eventMouseEnter)="onEventMouseEnter($event)"></full-calendar>
    </div>
</div>