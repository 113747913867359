import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-data-input-dialog',
  templateUrl: './data-input-dialog.component.html',
  styleUrls: ['./data-input-dialog.component.scss']
})
export class DataInputDialogComponent implements OnInit {

  title: string;
  value: number;

  constructor(
    private dialogRef: MatDialogRef<DataInputDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    if(data) {
      this.title = data.title;
    }
  }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    if(this.value != null) {
      this.dialogRef.close(this.value);
      return;
    }
    this.dialogRef.close(this.value);
  }

}
