import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';

import * as models from 'app/models';
import * as services from 'app/services';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-user-select',
  templateUrl: './user-select.component.html',
  styleUrls: ['./user-select.component.scss']
})
export class UserSelectComponent implements OnInit, OnChanges {

  @Input() excludedUserIds: string[] = [];
  @Input() includeNullValue: boolean = false;

  userIDValue: string;
  @Input()
  get userId() {
    return this.userIDValue;
  }
  @Output() userIdChange = new EventEmitter();
  set userId(val) {
    this.userIDValue = val;
    this.setForm();
    this.userIdChange.emit(this.userIDValue);
    //this.selectedUsersChange.emit(this.selectedUsers);
  }
  public userCtrl: FormControl = new FormControl();
  public userFilterCtrl: FormControl = new FormControl();
  public filteredUsers: ReplaySubject<models.User[]> = new ReplaySubject<models.User[]>(1);
  protected _onDestroy = new Subject<void>();

  users: Array<models.User> = [];

  protected filterUsers() {
    if (!this.users) {
      return;
    }
    let filteredUsers = this.users.filter(i => !this.excludedUserIds.includes(i.id) || i.id == this.userId);

    // get the search keyword
    let search = this.userFilterCtrl.value;
    if (!search) {
      this.filteredUsers.next(filteredUsers.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the Groups
    let filteredGroups = filteredUsers.filter(group => {
      return (group.displayName != null && group.displayName.toLowerCase().indexOf(search) > -1)
    })
    this.filteredUsers.next(filteredGroups);
  }

  constructor(
    private helperService: services.HelperService
    , private userService: services.UserService
  ) { }

  ngOnInit(): void {
    this.retrieveUsers();

    // listen for search field value changes
    this.userFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterUsers();
      });

    if(this.excludedUserIds != null && this.excludedUserIds.length > 0){
      this.filterUsers();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.excludedUserIds){
      this.filterUsers();
    }
  }

  async retrieveUsers() {
    const companyId: string = this.helperService.currentCompanyId;
    let users = await this.userService.getUsersForCompanyWithCaching(
      companyId,
      true,
      true
    );
    this.users = this.helperService.sortArrayByStringField(users, 'displayName');

    if(this.includeNullValue){
      this.users.unshift({
        id: 'not_specified',
        displayName: 'All'
      });
    }

    this.filteredUsers.next(this.users.slice());
    this.setForm();
  }

  // getUsers(currentUserId: string) {
  //   let alreadySelectedUserIds = this.dataSubmissionDisplays.map(i => i.dataSubmission.userId);
  //   return this.users.filter((i) => !alreadySelectedUserIds.includes(i.id) || i.id == currentUserId);
  // }

  setForm() {
    this.userCtrl.setValue(this.users.filter(i => i.id == this.userId));
  }

}
