import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ExportToCsv, Options } from 'export-to-csv';
import { NgxCsvParser } from 'ngx-csv-parser';
import { NgxCSVParserError } from 'ngx-csv-parser';

import * as models from '../../models';
import * as services from '../../services';
import * as viewModels from '../../viewmodels';

@Component({
  selector: 'app-template-upload',
  templateUrl: './template-upload.component.html',
  styleUrls: ['./template-upload.component.scss']
})
export class TemplateUploadComponent implements OnInit {

  saveClicked: boolean = false;
  fileToUpload: File | null = null;
  templateName: string;
  selectedTemplateType: models.TemplateType;

  templateTypes = new models.TemplateTypeList().list;

  constructor(
    private dialogRef: MatDialogRef<TemplateUploadComponent>
    , @Inject(MAT_DIALOG_DATA) public data: any
    , private ngxCsvParser: NgxCsvParser
    , private notifierService: services.NotifierService
    , private templateService: services.TemplateService
  ) { 
    this.selectedTemplateType = data.selectedTemplate;
    if(this.selectedTemplateType == null){
      this.selectedTemplateType = models.TemplateType.Primary;
    }
   }

  ngOnInit(): void {
  }

  getTemplateTypes(): string[] {
    return Object.keys(this.templateTypes).map(t => models.TemplateType[t])
  }

  downloadExample() {
    let options: Options = { 
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: false, 
      showTitle: false,
      title: 'Example Import',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: false,
      filename: 'Example Import',
      //headers: ['Phase', 'Day in Phase', 'Task Description', 'High Priority', "Anytime", "Start Time", "Cloud Attachments", "Notes"]
    };
  
    let csvExporter = new ExportToCsv(options);

    let templateSteps: Array<any> = [
      {
        phase: 'phase',
        daysSinceLastPhase: 'daysSinceLastPhase',
        dayInPhase: 'dayInPhase',
        //dayTotal: 'dayTotal',
        taskDescription: 'taskDescription',
        highPriority: 'highPriority',
        anytime: 'anytime',
        startTime: 'startTime',
        dayTotalHarvest: 'dayTotalHarvest',
        cloudAttachments: 'cloudAttachments',
        notes: 'notes',
        tags: 'tags',
        subtasks: 'subtasks',
        dataCollectionDefinitions: 'dataCollectionDefinitions',
      }
    ];

    csvExporter.generateCsv(templateSteps);
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
  } 

  upload() {

    if(this.templateName == null || this.templateName == ''){
      this.notifierService.error('Must specify a name for new Template');
      return;
    }

    if(this.fileToUpload == null){
      this.notifierService.error('Must select a file to upload');
      return;
    }

    this.saveClicked = true;

    // Parse the file you want to select for the operation along with the configuration
    this.ngxCsvParser.parse(this.fileToUpload, { header: true, delimiter: ',' })
      .pipe().subscribe((result: Array<viewModels.TemplateExport>) => {

        this.validate(result);
      }, (error: NgxCSVParserError) => {
        console.log('Error', error);
        this.notifierService.error('There was an error uploading file');
        this.saveClicked = false;
      });
  }

  async validate(results: Array<viewModels.TemplateExport>) {
    let errors: Array<boolean> = [];
    let validatedRows: Array<viewModels.TemplateExport> = [];

    for (let i = 0; i < results.length; i++) {
      const result = new viewModels.TemplateExport(results[i]);

      if(!result.validate()){
        errors.push(true);
      }

      validatedRows.push(result);
    }

    if(errors.length > 0){
      this.notifierService.error(`There were ${errors.length} row${errors.length > 1 ? 's' : ''} with errors in the uploaded file`);
      this.saveClicked = false;
      return;
    }

    if(this.selectedTemplateType == models.TemplateType.Secondary && (new Set(validatedRows.map(obj => obj.phase))).size > 1) {
      this.notifierService.error(`Cannot upload a template with more than one phase for a workflow.  Please limit to one phase.`);
      this.saveClicked = false;
      return;
    }

    if(this.selectedTemplateType == models.TemplateType.Secondary) {
      validatedRows.forEach(validatedRow => {
        validatedRow.phase = this.templateName;
      });
    }

    this.import(validatedRows);
  }

  async import(result: Array<viewModels.TemplateExport>) {
    let id = await this.templateService.import(this.templateName, this.selectedTemplateType, result);
    this.dialogRef.close(id)
    this.saveClicked = false;
  }
}
