import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EventInput } from '@fullcalendar/core';
import { EventBulkEditAssignDialogComponent, EventBulkUpdateDialogComponent } from 'app/dialogs';

import * as models from 'app/models';
import * as services from 'app/services';
import * as viewmodels from 'app/viewmodels';
import { Timestamp } from 'firebase/firestore';

@Component({
  selector: 'app-bulk-edit-btns',
  templateUrl: './bulk-edit-btns.component.html',
  styleUrls: ['./bulk-edit-btns.component.scss']
})
export class BulkEditBtnsComponent implements OnInit {

  @Input() display: boolean = true;
  @Input() bulkEditType: string;
  @Input() events: Array<EventInput>;
  @Input() allEventSubs: Array<viewmodels.EventSubscription>;

  @Output() complete = new EventEmitter();
  //@Output() clearSelected = new EventEmitter();

  savingValue: boolean = false;
  @Input()
  get saving() {
    return this.savingValue;
  }
  @Output() savingChange = new EventEmitter();
  set saving(val) {
    this.savingValue = val;
    this.savingChange.emit(this.savingValue);
  }

  eventSubs: Array<viewmodels.EventSubscription>;
  harvestIdsToEdit: Array<string> = [];
  groupIdsToEdit: Array<string> = [];

  selectedUserIds: Array<string> = [];
  selectedTeamIds: Array<string> = [];
  keepAllOtherUsers: boolean = false;
  keepAllOtherTeams: boolean = false;

  get bulkEditWarning() {
    switch (this.bulkEditType) {
      case models.bulkEditType.delete:
        return `Please select events to delete.`;
      case models.bulkEditType.assign:
        return `Please select events to assign.`;
      case models.bulkEditType.complete:
        return `Please select events to mark as complete.`;
      case models.bulkEditType.incomplete:
        return `Please select events to mark as incomplete.`;
    }
  }

  constructor(
    private notifierService: services.NotifierService
    , private eventService: services.EventService
    , private claimsService: services.ClaimsService
    , private dialog: MatDialog
  ) { }

  ngOnInit(): void {
  }

  compilePrerequisites() { 
    this.harvestIdsToEdit = Array.from(new Set(this.events.filter(i => i.type == 'harvests').map(i => i.parentId)));
    this.groupIdsToEdit = Array.from(new Set(this.events.filter(i => i.type === 'groups').map(i => i.parentId)));

    let parentIds: string[] = Array.from(new Set(this.events.map(i => i.parentId)));

    this.eventSubs = this.allEventSubs.filter(i => parentIds.includes(i.id));
  }

  saveBulkEdit() {
    this.compilePrerequisites();

    switch (this.bulkEditType) {
      case models.bulkEditType.delete:
        this.deleteClick();
        break;
      case models.bulkEditType.assign:
        // // let parentIds: string[] = Array.from(new Set(this.selectedEvents.map(i => i.parentId)));
    
        // // let subs = this.eventSubs.filter(i => parentIds.includes(i.id));
    
        // let dialogRef = this.dialog.open(EventBulkEditAssignDialogComponent);
    
        // dialogRef.afterClosed().subscribe(async (result) => {
        //   if (result && result.save) {
        //     this.selectedUserIds = result.selectedUserIds;
        //     this.selectedTeamIds = result.selectedTeamIds;
        //     this.keepAllOtherUsers = result.keepAllOtherUsers;
        //     this.keepAllOtherTeams = result.keepAllOtherTeams;
        //     this.assignClick();
        //   }
        // })
        this.assignClick();
        break;
      case models.bulkEditType.complete:
        this.completeClick(true);
        break;
      case models.bulkEditType.incomplete:
        this.completeClick(false);
        break;
    }
  }

  cancelBulkEdit() {
    this.complete.emit();
  }

  openAssignDialog() {
    let dialogRef = this.dialog.open(EventBulkEditAssignDialogComponent);

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result && result.save) {
        this.selectedUserIds = result.selectedUserIds;
        this.selectedTeamIds = result.selectedTeamIds;
        this.keepAllOtherUsers = result.keepAllOtherUsers;
        this.keepAllOtherTeams = result.keepAllOtherTeams;
      }
    })
  }

  completeClick(complete: boolean) {
    let message: string = `Are you sure you want to mark ${this.events.length} events in `;
    if(this.harvestIdsToEdit.length == 1) {
      message += `${this.harvestIdsToEdit.length} Harvest `;
    } else if(this.harvestIdsToEdit.length > 1){
      message += `${this.harvestIdsToEdit.length} Harvests `;
    }

    if(this.groupIdsToEdit.length > 0 && this.harvestIdsToEdit.length > 0) {
      message += 'and ';
    }

    if(this.groupIdsToEdit.length == 1) {
      message += `${this.groupIdsToEdit.length} Group `;
    } else if(this.groupIdsToEdit.length > 1){
      message += `${this.groupIdsToEdit.length} Groups `;
    }

    message += `as ${complete ? 'Complete': 'Incomplete'}?`;
    
    this.notifierService.confirm(`${complete ? 'Complete': 'Incomplete'} Events?`, message, () => {
      this.markComplete(complete);
    }, () => {});
  }

  markComplete(complete: boolean) {
    this.saving = true;
    let requests = []

    for (let i = 0; i < this.harvestIdsToEdit.length; i++) {
      const harvestId = this.harvestIdsToEdit[i];

      let eventsInHarvest = this.events.filter(i => i.parentId === harvestId);
      let idsToEdit = eventsInHarvest.map(i => i.eventObj.id);
      let sub = this.eventSubs.find(i => i.id === harvestId);
      let eventList = sub.eventList;

      idsToEdit.forEach(eventId => {
        let foundEvent = eventList.find(i => i.id === eventId);

        if(complete && !foundEvent.completed) {
          foundEvent.completed = true;
          this.updateDiscussion(foundEvent);
        }
        if(!complete && foundEvent.completed) {
          foundEvent.completed = false;
          this.updateDiscussion(foundEvent);
        }
      });

      let dbEvent: models.DbEvent = {
        uid: sub.eventListId,
        list: eventList
      }

      requests.push(this.eventService.save(false, harvestId, dbEvent));
    }

    for (let i = 0; i < this.groupIdsToEdit.length; i++) {
      const groupId = this.groupIdsToEdit[i];

      let eventsInHarvest = this.events.filter(i => i.parentId === groupId);
      let idsToEdit = eventsInHarvest.map(i => i.eventObj.id);
      let sub = this.eventSubs.find(i => i.id === groupId);
      let eventList = sub.eventList;

      idsToEdit.forEach(eventId => {
        let foundEvent = eventList.find(i => i.id === eventId);

        if(complete && !foundEvent.completed) {
          foundEvent.completed = true;
          this.updateDiscussion(foundEvent);
        }
        if(!complete && foundEvent.completed) {
          foundEvent.completed = false;
          this.updateDiscussion(foundEvent);
        }
      });

      let dbEvent: models.DbEvent = {
        uid: sub.eventListId,
        list: eventList
      }

      requests.push(this.eventService.save(true, groupId, dbEvent));
    }

    Promise.all(requests).then(() => {
      this.notifierService.success(`Successfully marked ${this.events.length} events completed`);
      this.complete.emit();
      this.saving = false;
    });
  }

  updateDiscussion(event: models.Event) {
    let newDis: models.Discussion = {
      discussionType: models.DiscussionType.CompletedStatus,
      userId: this.claimsService.currentUserId(),
      timestamp: Timestamp.now(),
      content: event.completed ? 'true' : 'false',
    }

    if(event.discussions == null) {
      event.discussions = [];
    }
    event.discussions.push(newDis);
  }

  assignClick() {
    let message: string = `Are you sure you want to assign `;

    if(this.selectedUserIds.length == 1) {
      message += `${this.selectedUserIds.length} User`;
    } else if(this.selectedUserIds.length > 1){
      message += `${this.selectedUserIds.length} Users`;
    }

    if(this.selectedUserIds.length > 0 && this.selectedTeamIds.length > 0) {
      message += ' and ';
    }

    if(this.selectedTeamIds.length == 1) {
      message += `${this.selectedTeamIds.length} Team`;
    } else if(this.selectedTeamIds.length > 1){
      message += `${this.selectedTeamIds.length} Teams`;
    }

    message += ` to ${this.events.length} events from `;
    // if(this.groupIdsToEdit.length > 0 && this.harvestIdsToEdit.length > 0) {
    //   message += ` to ${this.events.length} events from `;
    // }

    if(this.harvestIdsToEdit.length == 1) {
      message += `${this.harvestIdsToEdit.length} Harvest`;
    } else if(this.harvestIdsToEdit.length > 1){
      message += `${this.harvestIdsToEdit.length} Harvests`;
    }

    if(this.groupIdsToEdit.length > 0 && this.harvestIdsToEdit.length > 0) {
      message += ' and ';
    }

    if(this.groupIdsToEdit.length == 1) {
      message += `${this.groupIdsToEdit.length} Group`;
    } else if(this.groupIdsToEdit.length > 1){
      message += `${this.groupIdsToEdit.length} Groups`;
    }

    message += `?`;
    
    this.notifierService.confirm('Assign Events?', message, () => {
      this.assign();
    }, () => {});
  }

  assign() {
    this.saving = true;
    let requests = []

    for (let i = 0; i < this.harvestIdsToEdit.length; i++) {
      const harvestId = this.harvestIdsToEdit[i];

      let eventsInHarvest = this.events.filter(i => i.parentId === harvestId);
      let idsToEdit = eventsInHarvest.map(i => i.eventObj.id);
      let sub = this.eventSubs.find(i => i.id === harvestId);
      let eventList = sub.eventList;

      idsToEdit.forEach(eventId => {
        let foundEvent = eventList.find(i => i.id === eventId);
        if(foundEvent) {
          foundEvent.assignedUserIds = this.mergeIds(this.keepAllOtherUsers, foundEvent.assignedUserIds || [], this.selectedUserIds);
          foundEvent.assignedTeamIds = this.mergeIds(this.keepAllOtherTeams, foundEvent.assignedTeamIds || [], this.selectedTeamIds);
        }
      });

      let dbEvent: models.DbEvent = {
        uid: sub.eventListId,
        list: eventList
      }

      requests.push(this.eventService.save(false, harvestId, dbEvent));
    }

    for (let i = 0; i < this.groupIdsToEdit.length; i++) {
      const groupId = this.groupIdsToEdit[i];

      let eventsInHarvest = this.events.filter(i => i.parentId === groupId);
      let idsToEdit = eventsInHarvest.map(i => i.eventObj.id);
      let sub = this.eventSubs.find(i => i.id === groupId);
      let eventList = sub.eventList;

      idsToEdit.forEach(eventId => {
        let foundEvent = eventList.find(i => i.id === eventId);
        if(foundEvent) {
          foundEvent.assignedUserIds = this.mergeIds(this.keepAllOtherUsers, foundEvent.assignedUserIds || [], this.selectedUserIds);
          foundEvent.assignedTeamIds = this.mergeIds(this.keepAllOtherTeams, foundEvent.assignedTeamIds || [], this.selectedTeamIds);
        }
      });

      let dbEvent: models.DbEvent = {
        uid: sub.eventListId,
        list: eventList
      }

      requests.push(this.eventService.save(true, groupId, dbEvent));
    }

    Promise.all(requests).then(() => {
      this.notifierService.success(`Successfully assigned ${this.events.length} events`);
      this.complete.emit();
      this.saving = false;
    });
  }

  mergeIds(keepCurrent: boolean, currentIds: Array<string>, newIds: Array<string>) {
    return !keepCurrent ? newIds : Array.from(new Set([...currentIds, ...newIds]))
  }

  deleteClick() {
    let message: string = `Are you sure you want to delete ${this.events.length} events from`;
    if(this.harvestIdsToEdit.length == 1) {
      message += ` ${this.harvestIdsToEdit.length} Harvest`;
    } else if(this.harvestIdsToEdit.length > 1){
      message += ` ${this.harvestIdsToEdit.length} Harvests`;
    }

    if(this.groupIdsToEdit.length > 0 && this.harvestIdsToEdit.length > 0) {
      message += ' and ';
    }

    if(this.groupIdsToEdit.length == 1) {
      message += `${this.groupIdsToEdit.length} Group`;
    } else if(this.groupIdsToEdit.length > 1){
      message += `${this.groupIdsToEdit.length} Groups`;
    }

    message += `?`;
    
    this.notifierService.confirm('Delete Events?', message, () => {
      this.delete();
    }, () => {});
  }

  async delete() {

    this.saving = true;
    let requests = []

    for (let i = 0; i < this.harvestIdsToEdit.length; i++) {
      const harvestId = this.harvestIdsToEdit[i];

      let eventsInHarvest = this.events.filter(i => i.parentId === harvestId);
      let idsToRemove = eventsInHarvest.map(i => i.eventObj.id);
      let sub = this.eventSubs.find(i => i.id === harvestId);
      let eventList = sub.eventList.filter(i => !idsToRemove.includes(i.id));

      let dbEvent: models.DbEvent = {
        uid: sub.eventListId,
        list: eventList
      }

      requests.push(this.eventService.save(false, harvestId, dbEvent));
    }

    for (let i = 0; i < this.groupIdsToEdit.length; i++) {
      const groupId = this.groupIdsToEdit[i];

      let eventsInHarvest = this.events.filter(i => i.parentId === groupId);
      let idsToRemove = eventsInHarvest.map(i => i.eventObj.id);
      let sub = this.eventSubs.find(i => i.id === groupId);
      let eventList = sub.eventList.filter(i => !idsToRemove.includes(i.id));

      let dbEvent: models.DbEvent = {
        uid: sub.eventListId,
        list: eventList
      }

      requests.push(this.eventService.save(true, groupId, dbEvent));
    }

    Promise.all(requests).then(() => {
      this.notifierService.success(`Successfully deleted ${this.events.length} events`);
      this.complete.emit();
      this.saving = false;
    });
  }

}
