import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormBuilder, FormControl, FormArray } from '@angular/forms';
import { SessionStorageService } from 'angular-web-storage';

import * as models from '../../models';
import * as services from '../../services';

@Component({
  selector: 'app-user-select-dialog',
  templateUrl: './user-select.component.html',
  styleUrls: ['./user-select.component.scss']
})
export class UserSelectComponent implements OnInit {
  // userIdsForm: FormGroup;
  assignedUserIds: Array<string> = [];
  assignedTeamIds: Array<string> = [];

  //retrievedUsers: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<UserSelectComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { 
    this.assignedUserIds = data.userIds;
    this.assignedTeamIds = data.teamIds;
  }

  async ngOnInit() {
    // await this.getUsers();
    // this.initForm();
  }

  // initForm(): void {
  //   this.addCheckboxes();
  // }

  // private addCheckboxes() {
  //   this.users.forEach((o, i) => {
  //     let assigned: boolean = false;
  //     if(this.assignedUserIds.includes(o.id)){
  //       assigned = true;
  //     }
  //     const control = new FormControl(assigned);
  //     (this.userIdsForm.controls.users as FormArray).push(control);
  //   });
  // }

  // assignToAll() {
  //   this.userIdsForm.controls['users'].setValue(
  //       this.userIdsForm.controls['users'].value.map(value => true)
  //   );
  // }

  submit() {
    // const selectedUserIds = this.userIdsForm.value.users
    //   .map((v, i) => v ? this.users[i].id : null)
    //   .filter(v => v !== null);

    this.return(this.assignedUserIds, this.assignedTeamIds);
  }

  return(userIds: Array<string>, teamIds: Array<string>) {
    let dialogResult = {
      submitted: true,
      userIds: userIds,
      teamIds: teamIds
    }
    this.dialogRef.close(dialogResult)
  }

  // async getUsers() {
  //   let companyId = this.helperService.currentCompanyId;
  //   let userResponse = await this.userService.getUsersFoCompany(companyId, true)

  //   if(!userResponse.data.success){
  //     this.snackBar.open('Error getting Users', null, {duration: 3000, verticalPosition: 'top'});
  //     return;
  //   }

  //   userResponse.data.value.forEach(element => {
  //     let user: models.User = element;
  //     user.id = element.id;
  //     this.users.push(user);
  //   });

  //   this.retrievedUsers = true;
  // }
}
