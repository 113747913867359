<h2 mat-dialog-title>
    <span style="display: inline-block">Add to {{title}}</span>
</h2>
<mat-dialog-content>
    <div class="row">
        <div class="col-12">
            <mat-form-field appearance="outline">
                <mat-label>{{title}}</mat-label>
                <input matInput type="number" [(ngModel)]="value">
            </mat-form-field>
        </div>
    </div>
    
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button (click)="close()">Cancel</button>
    <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
    <button mat-button (click)="save()">Ok</button>
</mat-dialog-actions>