
<mat-form-field *ngIf="templatedTasks != null">
    <mat-label>Templated Task</mat-label>
    <mat-select [(ngModel)]="selectedTask">
      <mat-option>
          <ngx-mat-select-search [formControl]="taskFilterCtrl"
                                  placeholderLabel="Find Task..."
                                  noEntriesFoundLabel="no matching task found"></ngx-mat-select-search>
      </mat-option>
        <mat-option [value]="templatedTask" *ngFor="let templatedTask of filteredTasks | async">{{templatedTask.name}}</mat-option>
    </mat-select>
    <button mat-icon-button matSuffix [disabled]="selectedTask == null" (click)="redirect($event)">
      <mat-icon style="font-size: 20px">open_in_new</mat-icon>
    </button>
</mat-form-field>
