import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EventInput } from '@fullcalendar/common';

import * as models from 'app/models';
import * as services from 'app/services';
import * as viewmodels from 'app/viewmodels';
import { Timestamp } from 'firebase/firestore';

@Component({
  selector: 'app-event-bulk-update-dialog',
  templateUrl: './event-bulk-update-dialog.component.html',
  styleUrls: ['./event-bulk-update-dialog.component.scss']
})
export class EventBulkUpdateDialogComponent implements OnInit {

  events: Array<EventInput>;
  eventSubs: Array<viewmodels.EventSubscription>;

  harvestIdsToEdit: Array<string> = [];
  groupIdsToEdit: Array<string> = [];

  selectedUserIds: Array<string> = [];
  selectedTeamIds: Array<string> = [];

  removeAllOtherUsers: boolean = false;
  removeAllOtherTeams: boolean = false;

  completeStatus: string = 'no_change';

  saving: boolean = false;
  deleting: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<EventBulkUpdateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private notifierService: services.NotifierService,
    private eventService: services.EventService,
    private claimsService: services.ClaimsService
  ) { 
    if(data) {
      this.events = data.events;
      this.eventSubs = data.eventSubs;
    }
  }

  ngOnInit(): void {
    this.harvestIdsToEdit = Array.from(new Set(this.events.filter(i => i.type == 'harvests').map(i => i.parentId)));
    this.groupIdsToEdit = Array.from(new Set(this.events.filter(i => i.type === 'groups').map(i => i.parentId)));
  }

  saveClick() {
    this.notifierService.confirm('Save Events?', `Are you sure you want to save ${this.events.length} events in ${this.harvestIdsToEdit.length} Harvest(s) and ${this.groupIdsToEdit.length} Task Group(s)?`, () => {
      this.save();
    }, () => {});
  }

  save() {
    this.saving = true;
    let requests = []

    for (let i = 0; i < this.harvestIdsToEdit.length; i++) {
      const harvestId = this.harvestIdsToEdit[i];

      let eventsInHarvest = this.events.filter(i => i.parentId === harvestId);
      let idsToEdit = eventsInHarvest.map(i => i.eventObj.id);
      let sub = this.eventSubs.find(i => i.id === harvestId);
      let eventList = sub.eventList;

      idsToEdit.forEach(eventId => {
        let foundEvent = eventList.find(i => i.id === eventId);
        if(foundEvent) {
          foundEvent.assignedUserIds = this.removeAllOtherUsers ? this.selectedUserIds : [...foundEvent.assignedUserIds, ...this.selectedUserIds];
          foundEvent.assignedTeamIds = this.removeAllOtherTeams ? this.selectedTeamIds : [...foundEvent.assignedTeamIds, ...this.selectedTeamIds];
        }

        if(this.completeStatus === 'complete' && !foundEvent.completed) {
          foundEvent.completed = true;
          this.updateDiscussion(foundEvent);
        }
        if(this.completeStatus === 'incomplete' && foundEvent.completed) {
          foundEvent.completed = false;
          this.updateDiscussion(foundEvent);
        }
      });

      let dbEvent: models.DbEvent = {
        uid: sub.eventListId,
        list: eventList
      }

      requests.push(this.eventService.save(false, harvestId, dbEvent));
    }

    for (let i = 0; i < this.groupIdsToEdit.length; i++) {
      const groupId = this.groupIdsToEdit[i];

      let eventsInHarvest = this.events.filter(i => i.parentId === groupId);
      let idsToEdit = eventsInHarvest.map(i => i.eventObj.id);
      let sub = this.eventSubs.find(i => i.id === groupId);
      let eventList = sub.eventList;

      idsToEdit.forEach(eventId => {
        let foundEvent = eventList.find(i => i.id === eventId);
        if(foundEvent) {
          foundEvent.assignedUserIds = this.removeAllOtherUsers ? this.selectedUserIds : [...foundEvent.assignedUserIds, ...this.selectedUserIds];
          foundEvent.assignedTeamIds = this.removeAllOtherTeams ? this.selectedTeamIds : [...foundEvent.assignedTeamIds, ...this.selectedTeamIds];
        }

        if(this.completeStatus === 'complete' && !foundEvent.completed) {
          foundEvent.completed = true;
          this.updateDiscussion(foundEvent);
        }
        if(this.completeStatus === 'incomplete' && foundEvent.completed) {
          foundEvent.completed = false;
          this.updateDiscussion(foundEvent);
        }
      });

      let dbEvent: models.DbEvent = {
        uid: sub.eventListId,
        list: eventList
      }

      requests.push(this.eventService.save(true, groupId, dbEvent));
    }

    Promise.all(requests).then(() => {
      this.notifierService.success(`Successfully deleted ${this.events.length} events`);
      this.dialogRef.close(true);
      this.saving = false;
    });
  }

  updateDiscussion(event: models.Event) {
    let newDis: models.Discussion = {
      discussionType: models.DiscussionType.CompletedStatus,
      userId: this.claimsService.currentUserId(),
      timestamp: Timestamp.now(),
      content: event.completed ? 'true' : 'false',
    }

    event.discussions.push(newDis);
  }

  deleteClick() {
    this.notifierService.confirm('Delete Events?', `Are you sure you want to delete ${this.events.length} events from ${this.harvestIdsToEdit.length} Harvest(s) and ${this.groupIdsToEdit.length} Task Group(s)?`, () => {
      this.delete();
    }, () => {});
  }

  async delete() {

    this.deleting = true;
    let requests = []

    for (let i = 0; i < this.harvestIdsToEdit.length; i++) {
      const harvestId = this.harvestIdsToEdit[i];

      let eventsInHarvest = this.events.filter(i => i.parentId === harvestId);
      let idsToRemove = eventsInHarvest.map(i => i.eventObj.id);
      let sub = this.eventSubs.find(i => i.id === harvestId);
      let eventList = sub.eventList.filter(i => !idsToRemove.includes(i.id));

      let dbEvent: models.DbEvent = {
        uid: sub.eventListId,
        list: eventList
      }

      requests.push(this.eventService.save(false, harvestId, dbEvent));
    }

    for (let i = 0; i < this.groupIdsToEdit.length; i++) {
      const groupId = this.groupIdsToEdit[i];

      let eventsInHarvest = this.events.filter(i => i.parentId === groupId);
      let idsToRemove = eventsInHarvest.map(i => i.eventObj.id);
      let sub = this.eventSubs.find(i => i.id === groupId);
      let eventList = sub.eventList.filter(i => !idsToRemove.includes(i.id));

      let dbEvent: models.DbEvent = {
        uid: sub.eventListId,
        list: eventList
      }

      requests.push(this.eventService.save(true, groupId, dbEvent));
    }

    Promise.all(requests).then(() => {
      this.notifierService.success(`Successfully deleted ${this.events.length} events`);
      this.dialogRef.close(true);
      this.deleting = false;
    });
  }

}
