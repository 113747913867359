
export class TemplateExport {
  phase?: string;
  daysSinceLastPhase?: string;
  dayInPhase?: string;
  dayTotal?:string;
  taskDescription?: string;
  highPriority?: boolean;
  anytime?: boolean;
  startTime?: string;
  cloudAttachments?: string;
  notes?: string;
  tags?: string;
  subtasks?: string;
  dataCollectionDefinitions?: string;

  constructor(model: TemplateExport){
    this.phase = model.phase;
    this.daysSinceLastPhase = model.daysSinceLastPhase;
    this.dayInPhase = model.dayInPhase;
    this.dayTotal = model.dayTotal;
    this.taskDescription = model.taskDescription;
    this.highPriority = model.highPriority != null && model.highPriority.toString() != '' ? JSON.parse(model.highPriority?.toString().toLowerCase()) : false;
    this.anytime = model.anytime != null && model.highPriority.toString() != '' ? JSON.parse(model.anytime?.toString().toLowerCase()) : true;
    this.startTime = model.startTime;
    this.cloudAttachments = model.cloudAttachments;
    this.notes = model.notes;
    this.tags = model.tags;
    this.subtasks = model.subtasks;
    this.dataCollectionDefinitions = model.dataCollectionDefinitions;
  }

  public validate?() : boolean {
    if (typeof this.phase !== 'string' || this.phase == null) {
      return false;
    }
    if (this.daysSinceLastPhase == null || (this.daysSinceLastPhase != '' && typeof +this.daysSinceLastPhase !== 'number')) {
      return false;
    }
    if (typeof this.dayInPhase !== 'string' || this.dayInPhase == null) {
      return false;
    }
    // if (typeof this.dayTotal !== 'string' || this.dayTotal == null) {
    //   return false;
    // }
    if (typeof this.taskDescription !== 'string' || this.taskDescription == null) {
      return false;
    }
    if (typeof this.highPriority !== 'boolean' || this.highPriority == null) {
      return false;
    }
    if (typeof this.anytime !== 'boolean' || this.anytime == null) {
      return false;
    }
    if (this.anytime == false && (typeof this.startTime !== 'string' || this.startTime == null || !this.startTime.match(/^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/) )) {
      return false;
    }
    if (typeof this.cloudAttachments !== 'string' || this.cloudAttachments == null) {
      return false;
    }
    if (typeof this.notes !== 'string' || this.notes == null) {
      return false;
    }
    if (typeof this.tags !== 'string' || this.tags == null) {
      return false;
    }
    if (typeof this.subtasks !== 'string' || this.subtasks == null) {
      return false;
    }
    if (typeof this.dataCollectionDefinitions !== 'string' || this.dataCollectionDefinitions == null) {
      return false;
    }

    return true;
  }
}