import { DataCollectionDefinition } from "./dataCollectionDefinition";
import { Step } from "./step";
import { Subtask } from "./subtask";

export interface TemplatedTask {
    id?: string;
    name?: string;
    description?: string;
    precedence?: number;
    highPriority?: boolean;
    anyTime?: boolean;
    startTime?: string;
    cloudAttachments?: Array<string>;
    attachments?: Array<string>;
    notes?: Array<string>;
    tags?: Array<string>;
    estimatedTime?: string;
    timeSpentRequired?: boolean;
    assignedTeamIds?: Array<string>;
    subtasks?: Array<Subtask>;
    dataCollectionDefinitions?: Array<DataCollectionDefinition>;
}

export class TemplatedTask {
    constructor(name: string, step: Step) {
        this.name = name;
        this.description = step.description || '';
        if (step.precedence) {
            this.precedence = step.precedence;
        }
        if (step.highPriority) {
            this.highPriority = step.highPriority;
        }
        if (step.anyTime) {
            this.anyTime = step.anyTime;
        }
        if (step.startTime) {
            this.startTime = step.startTime;
        }
        if (step.cloudAttachments) {
            this.cloudAttachments = step.cloudAttachments;
        }
        if (step.attachments) {
            this.attachments = step.attachments;
        }
        if (step.estimatedTime) {
            this.estimatedTime = step.estimatedTime;
        }
        if (step.timeSpentRequired) {
            this.timeSpentRequired = step.timeSpentRequired;
        }

        this.tags = step.tags || [];
        this.notes = step.notes || [];
        this.assignedTeamIds = step.assignedTeamIds || [];
        this.subtasks = step.subtasks || [];
        this.dataCollectionDefinitions = step.dataCollectionDefinitions || [];
    }
}