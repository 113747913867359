<h2 mat-dialog-title>
    <span style="display: inline-block">Data Collection</span>
    <button style="float: right" mat-icon-button (click)="add()">
      <mat-icon>add</mat-icon>
    </button>
</h2>
<mat-dialog-content style="min-width:50vw">
    <!-- <div class="row" *ngFor="let item of selectList; let i = index">
        <div class="col-12">
            <mat-form-field appearance="outline">
                <mat-select placeholder="Select from Data Library" [(ngModel)]="item.id">
                    <mat-option *ngFor="let option of dataLibrary" [value]="option.id">{{ option.name }}<span *ngIf="option.unit != null"> ({{getUnit(option.unit)}})</span></mat-option>
                </mat-select>
                <button mat-icon-button matSuffix (click)="remove(i)">
                    <mat-icon>delete</mat-icon>
                </button>
            </mat-form-field>
        </div>
    </div> -->




  <div *ngIf="selectList.length != 0" cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
    <div class="example-box" *ngFor="let item of selectList; let i = index" cdkDrag>
      <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
      <div class="row">
        <div class="col-12">
            <mat-form-field class="subtask_input" appearance="outline">
              <div matPrefix class="example-handle" cdkDragHandle>
                <mat-icon>drag_handle</mat-icon>
              </div>
              <mat-select placeholder="Select from Data Library" [(ngModel)]="item.id">
                  <!-- <mat-option *ngIf="canSeeDataLibraryAdd" value="add">
                      <mat-icon>add</mat-icon> Add to Data Library
                  </mat-option> -->
                  <mat-option *ngFor="let option of dataLibrary" [value]="option.id">{{ option.name }}<span *ngIf="option.unit != null"> ({{getUnit(option.unit)}})</span></mat-option>
              </mat-select>
              <button mat-icon-button matSuffix (click)="remove(i)">
                  <mat-icon>delete</mat-icon>
              </button>
            </mat-form-field>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
    <div class="row" style="width: 100%;">
        <div class="col-6">
            <button mat-button class="redirect-btn" (click)="redirectToDataLibrary()">Go to Data Library</button>
        </div>
        <div class="col-6" align="end">
            <button mat-button (click)="close()">Cancel</button>
            <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
            <button mat-button (click)="save()">Ok</button>
        </div>
    </div>
</mat-dialog-actions>