import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Calendar, CalendarOptions, EventClickArg, EventDef, EventDropArg, EventInput, FullCalendarComponent } from '@fullcalendar/angular';
import { LeaflogixSyncBtnComponent } from 'app/buttons/leaflogix-sync-btn/leaflogix-sync-btn.component';
import { FilterSectionComponent } from '../filter-section/filter-section.component';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import { DeviceDetectorService } from 'ngx-device-detector';
import { EditRecurringEventsDialogComponent, EventAddComponent, EventUpdateComponent } from 'app/dialogs';
import { MatDialog } from '@angular/material/dialog';
import * as equal from 'fast-deep-equal';

import { AppStorage } from 'app/services';
import * as models from 'app/models';
import * as services from 'app/services';
import * as viewmodels from 'app/viewmodels';
import { Subscription, forkJoin } from 'rxjs';
import { Timestamp, onSnapshot, Unsubscribe } from '@angular/fire/firestore';
import tippy from 'tippy.js';

@Component({
  selector: 'app-calendar-split',
  templateUrl: './calendar-split.component.html',
  styleUrls: ['./calendar-split.component.scss']
})
export class CalendarSplitComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('calendar') calendarComponent: FullCalendarComponent;
  calendarApi: Calendar;
  @ViewChild(FilterSectionComponent) filterSectionComponent: FilterSectionComponent;
  @ViewChild(LeaflogixSyncBtnComponent) leaflogixSyncBtnComponent: LeaflogixSyncBtnComponent;

  companySub: Subscription;

  currentFilterRequest: viewmodels.GetEventsRequest;
  //eventSub: Unsubscribe;
  queries: Array<viewmodels.ColGroupQuery> = [];
  filterResponse: viewmodels.FilterResponse;
  filteredHarvestPhasesByZone: viewmodels.FilteredHarvestPhasesByZone = {zoneIds: [], harvestPhases: []}
  
  events: models.Event[] = [];
  // get events() {
  //   return this.queries.flatMap(i => i.events || []);
  // }
  gettingEvents: boolean = false;

  users: Array<models.User> = [];
  teams: Array<models.Team> = [];
  zones: Array<models.Zone> = [];
  harvests: Array<models.Harvest> = [];//have to get them because of zone load - once we switch to storing Zone on the Event, we can remove this
  retrievedInitial: boolean = false;

  viewType: string = 'dayGridMonth';
  viewStart: Date;
  viewEnd: Date;
  weeklyListView: boolean = true;

  tippyInstance: any;
  displayTooltip: boolean;

  calendarOptions: CalendarOptions = {
    initialView: 'dayGridMonth',
    plugins: [dayGridPlugin, listPlugin, timeGridPlugin, interactionPlugin, bootstrapPlugin],
    dateClick: this.addEvent.bind(this),
    views: {
      dayGridMonth: { buttonText: this.deviceService.isMobile() ? 'm' : 'month' },
      dayGridWeek: {
        buttonText: this.deviceService.isMobile() ? 'w' : 'week',
        eventLimit: 20
      },
      listDay: { buttonText: this.deviceService.isMobile() ? 'd' : 'day' },
      //timeGridWeek: { buttonText: this.deviceService.isMobile() ? 'W3' : 'Week3' },
      listWeek: { buttonText: this.deviceService.isMobile() ? 'w' : 'week' }
    },
    headerToolbar: {
      left: 'title',
      //center: 'title',
      right: 'today dayGridMonth,listWeek,listDay prev,next'
    },
    eventClick: this.eventClick.bind(this),
    height: 'auto',
    //eventStartEditable: await this.canDragEvents(),
    eventDrop: this.eventDrop.bind(this),
    //eventSources: this.calendarEvents,
    eventOrder: this.eventOrder.bind(this),
    //eventContent: this.eventContent.bind(this),
    eventClassNames: this.eventClassName.bind(this),
    datesSet: this.datesSet.bind(this),
    eventDisplay: 'list-item',
    dayMaxEventRows: 12,
    //eventContent: this.eventContent.bind(this)
    eventTextColor: 'black',
    eventMouseEnter: this.onEventMouseEnter.bind(this),
  }

  get canSeeWeeklyListSlider(): boolean {
    if (this.viewType == 'listWeek' || this.viewType == 'dayGridWeek') {
      return true;
    }
    return false;
  }

  async canDragEvents(): Promise<boolean> {
    return (await this.claimsService.getRoleAwait())?.permissions?.includes(models.Permissions.editEvent);
  }

  onEventMouseEnter(arg: any) {
    if(!this.displayTooltip) {
      return;
    }
    let tooltipHTML = '<div><span>' 
                      + arg.event.title
                      +'</span></div>';
    this.tippyInstance = tippy(arg.el, {
      content: tooltipHTML,
      hideOnClick: true,
      allowHTML: true,
      onShow(instance) {
        instance.popper.style.backgroundColor = 'white';
        instance.popper.style.color = '#494949';
        instance.popper.style.fontSize = '14px';
        instance.popper.style.fontWeight = 'normal';
        instance.popper.style.padding = '5px';
      },
      onHide(instance) {
        instance.popper.style.display = 'none';
      },
    })
  }

  constructor(
    private appStorage: AppStorage
    , private deviceService: DeviceDetectorService
    , private claimsService: services.ClaimsService
    , private dialog: MatDialog
    , private notifierService: services.NotifierService
    , private eventService: services.EventService
    , private helperService: services.HelperService
    , private userService: services.UserService
    , private teamService: services.TeamService
    , private calendarEventService: services.CalendarEventService
    , private harvestService: services.HarvestService
    , private zoneService: services.ZoneService
  ) { }

  async ngOnInit() {
    this.calendarOptions.eventStartEditable = await this.canDragEvents();
    this.displayTooltip = this.helperService.currentCompany.eventsTooltips ? this.helperService.currentCompany.eventsTooltips : false;
    this.companySub = this.helperService.getCurrentCompany().subscribe(data => {
      this.displayTooltip = data.eventsTooltips ? data.eventsTooltips : false;
    })
  }

  ngAfterViewInit(): void {
    this.calendarApi = this.calendarComponent.getApi();
  }

  ngOnDestroy() {
    // if(this.collectionGroupSub != null){
    //   this.collectionGroupSub.unsubscribe();
    // }
    if(this.companySub != null){
      this.companySub.unsubscribe();
    }
  }

  async getEvents() {
    if(this.filterResponse == null || this.viewStart == null || this.viewEnd == null){
      return;
    }

    const start = Timestamp.fromDate(this.viewStart);
    const end = Timestamp.fromDate(this.viewEnd);

    let userIds = [];
    if(this.filterResponse.onlyMyTasks) {
      userIds = [this.claimsService.currentUserId()]
    } else {
      userIds = this.filterResponse.userIds;
    }

    let request: viewmodels.GetEventsRequest = {
      harvestIds: this.filterResponse.harvestIds,
      groupIds: this.filterResponse.groupIds,
      phases: this.filterResponse.phases,
      teamIds: this.filterResponse.teamIds,
      userIds: userIds,
      tags: this.filterResponse.tags,
      start: start,
      end: end,
      completeStatus: this.filterResponse.completeStatus,
      zoneIds: this.filterResponse.zoneIds
    }

    let sameRequest = equal(request, this.currentFilterRequest);
    if(sameRequest){
      console.log('skipping creation of new subscribe because same filters are used')
      return;
    }

    // if(this.eventSub != null) {
    //   this.eventSub();
    // }
    this.queries.forEach(query => {
      query.unsubscribe();
    });

    if(request.zoneIds != null && request.zoneIds.length > 0) {
      if(!equal(request.zoneIds, this.filteredHarvestPhasesByZone.zoneIds)){
        let harvestPhases = await this.harvestService.getHarvestsAndPhasesByZoneIds(request.zoneIds);
        this.filteredHarvestPhasesByZone = {
          zoneIds: request.zoneIds,
          harvestPhases: harvestPhases
        }
      }
    }

    this.gettingEvents = true;
    this.currentFilterRequest = this.helperService.deepCopy(request);

    this.queries = this.eventService.getFilteredListener(request);

    this.queries.forEach(query => {
      query.unsubscribe = onSnapshot(query.query, (querySnapshot) => {
        let events: models.Event[] = [];
        querySnapshot.forEach(snap => {
          
          // if(request.phases?.length > 0){
          //   let phase: string = snap.get("phase");
          //   if(!request.phases.includes(phase)){
          //     return;
          //   }
          // }
  
          // if(request.teamIds?.length > 0){
          //   let teams: Array<string> = snap.get("assignedTeamIds");
          //   if(teams == null || !this.helperService.hasOverlap(request.teamIds, teams)){
          //     return;
          //   }
          // }
  
          // if(request.userIds?.length > 0){
          //   let users: Array<string> = snap.get("assignedUserIds");
          //   if(users == null || !this.helperService.hasOverlap(request.userIds, users)){
          //     return;
          //   }
          // }
  
          // if(request.tags?.length > 0){
          //   let tags: Array<string> = snap.get("tags");
          //   if(tags == null || !this.helperService.hasOverlap(request.tags, tags)){
          //     return;
          //   }
          // }
  
          let event: models.Event = snap.data();
          event.uid = snap.id;

          //filter by zone
          if (request.zoneIds != null && request.zoneIds.length > 0) {
            let foundHarvestPhases = this.filteredHarvestPhasesByZone.harvestPhases.find(i => i.harvestId == event.parentId);
            if(!foundHarvestPhases?.phases?.includes(event.phase)){
              return;
            }
          }
  
          events.push(event);
        })
  
        //let calendarEvents = this.convertEventsToFullcalendar(events);
        query.events = events;
        this.events = this.queries.flatMap(i => i.events || []);
        this.plotEvents(this.events);
        this.gettingEvents = false;
      })
    });
  }

  plotEvents(newEvents: models.Event[]) {
    const events = this.calendarApi.getEvents();

    //find current events to delete
    const eventIdsToPlot = newEvents.map(i => i.id);
    const eventsToDelete = events.filter(i => !eventIdsToPlot.includes(i.id));

    eventsToDelete.forEach(event => {
      event.remove();
    });

    // loop through each event in the current list
    for (let i = 0; i < newEvents.length; i++) {
      const newEvent = newEvents[i];

      // find the corresponding event in the list, if it exists
      const currentEvent = events.find(e => e.id === newEvent.id);

      // if the event isn't in the new list, delete it from the current list
      if (!currentEvent) {
        this.calendarApi.addEvent(this.calendarEventService.convertEventToInput(newEvent, this.users, this.teams, this.zones, this.harvests));
      } else {
        let eventsHaveNotChanged = equal(newEvent, currentEvent.extendedProps.eventObj);
        if(!eventsHaveNotChanged){
          this.calendarEventService.updateEvent(currentEvent, newEvent, this.users, this.teams, this.zones, this.harvests);
        }
      }
    }

    this.calendarApi.render();
  }

  async initialLoadFromFilter(result: viewmodels.FilterResponse) {
    this.filterResponse = result;
    this.initialLoad();
  }

  async initialLoad() {
    let companyId = this.helperService.currentCompanyId;
    let calls = [
      this.userService.getUsersForCompanyWithCaching(companyId, true, true),
      this.getTeams(),
      this.zoneService.getAll(),
      this.harvestService.getAllModels()
    ]

    forkJoin(calls).subscribe((data: any) => {
      this.users = data[0];
      this.teams = data[1];
      this.zones = data[2];
      this.harvests = data[3];

      this.retrievedInitial = true;

      this.getEvents();
    })

  }

  onFilter(result: viewmodels.FilterResponse) {
    this.filterResponse = result;

    // this.hasSetEvents = false;
    // this.setCalendarEvents();
    this.getEvents();
  }

  async getTeams(): Promise<models.Team[]> {
    let teams: models.Team[] = [];
    let teamResponse = await (await this.teamService.getAll().get()).toPromise()
    teamResponse.docs.forEach(doc => {
      let team: models.Team = doc.data();
      team.uid = doc.id;
      teams.push(team);
    })
    return teams;
  }

  datesSet(dateInfo) {
    this.viewType = dateInfo.view.type;
    this.viewStart = dateInfo.start;
    this.viewEnd = dateInfo.end;

    if (this.viewType == 'listWeek' && !this.weeklyListView) {
      this.viewType = 'dayGridWeek';
      this.calendarComponent.getApi().changeView('dayGridWeek');
    }
    this.getEvents();
  }

  toggleListViewChange(newVal) {
    if (this.weeklyListView) {
      this.viewType = 'listWeek';
      this.calendarComponent.getApi().changeView('listWeek');
    } else {
      this.viewType = 'dayGridWeek';
      this.calendarComponent.getApi().changeView('dayGridWeek');
    }

    this.appStorage.weeklyListView = this.weeklyListView;
  }

  addedHarvest(harvestId: string){
    this.filterResponse.harvestIds.push(harvestId);
    //need to get new group in filter
    this.filterSectionComponent.harvestsChanged(this.filterResponse.harvestIds);
    this.getEvents();
  }

  addedHarvests(dialogResult: any) {
    if(dialogResult.harvestIds && dialogResult.harvestIds.length > 0) {
      this.filterResponse.harvestIds.push(...dialogResult.harvestIds);
      this.filterSectionComponent.harvestsChanged(this.filterResponse.harvestIds);
      this.getEvents();
    }

    // if(dialogResult.linked && this.leaflogixSyncBtnComponent != null){
    //   this.leaflogixSyncBtnComponent.sync();
    // }
  }

  addedGroup(groudId: string) {
    this.filterResponse.groupIds.push(groudId);
    //need to get new group in filter
    this.filterSectionComponent.groupsChanged(this.filterResponse.groupIds);
    this.getEvents();
  }

  addEvent(arg: DateClickArg) {
    if(!this.claimsService.userRole?.permissions?.includes(models.Permissions.addEvent)){
      return;
    }

    let data: any = {}
    if (this.viewType == 'listDay') {
      data.date = this.viewStart;
    } else if (arg != null) {
      data.date = arg.date
    }
    data.date?.setHours(12);//default hour to noon
    data.saveInDialog = true;

    let dialogRef = this.dialog.open(EventAddComponent, {//TODO: create an EventAddSplitComponent
      panelClass: 'med-width-dialog',
      data: data,
      autoFocus: true
    });
  }

  eventClick(arg: EventClickArg) {
    let start = arg.event.start;
    let type = arg.event._def.extendedProps.type;
    let parentId = arg.event._def.extendedProps.parentId;

    let harvestId;
    let groupId;
    if (type == 'harvests') {
      harvestId = parentId;
    } else if (type == 'groups') {
      groupId = parentId;
    } else {
      return;
    }
    let assignToGroup: boolean = false;
    if (harvestId == null && groupId != null) {
      assignToGroup = true;
    }

    let data: any = {
      event: arg.event._def.extendedProps.eventObj,
      start: start,
      assignToGroup: assignToGroup,
      eventList: this.events
    }

    if (assignToGroup) {
      data.groupId = groupId
    } else {
      data.harvestId = harvestId
    }
    data.saveInDialog = true;

    let dialogRef = this.dialog.open(EventUpdateComponent, {
      width: '800px',
      data: data
    });

    // dialogRef.afterClosed().subscribe(result => {
    //   if (!result) {
    //     return;
    //   }
    //   if (result.save) {
    //     //perform save
    //     let dbEvent: models.DbEvent = {
    //       uid: foundSub.eventListId,
    //       list: result.eventList
    //     }
    //     this.eventService.save(assignToGroup, foundSub.id, dbEvent);
    //   }
    //   else if (result.delete) {
    //     let list: models.Event[] = result.eventList;
    //     let index = list.indexOf(result.event);

    //     if (index > -1) {
    //       result.eventList.splice(index, 1);
    //     }

    //     let dbEvent: models.DbEvent = {
    //       uid: foundSub.eventListId,
    //       list: result.eventList
    //     }
    //     this.eventService.save(assignToGroup, foundSub.id, dbEvent);

    //   }
    // })
  }

  eventDrop(arg: EventDropArg) {
    let event: models.Event = arg.event._def.extendedProps.eventObj
    let type = arg.event._def.extendedProps.type;
    let parentId = arg.event._def.extendedProps.parentId;
    let start = arg.event.start;

    //find if it is recurring event
    if (event.repeatedEventId != null && event.repeatedEventId != '') {

      let dialogRef = this.dialog.open(EditRecurringEventsDialogComponent, {
        autoFocus: true,
        disableClose: false
      });

      dialogRef.afterClosed().subscribe(async result => {
        //reload list
        if (result) {
          this.updateEvent(event, result, start, type, parentId)
          //alert('save all now - ' + result);
        } else {
          arg.revert()
        }
        console.log(result);
      })
    } else {
      this.updateEvent(event, 'one', start, type, parentId);
    }
  }
  
  async updateEvent(event: models.Event, recurringDescission: string, start: Date, type: any, parentId: string) {
    let harvestId;
    let groupId;
    if (type == 'harvests') {
      harvestId = parentId;
    } else if (type == 'groups') {
      groupId = parentId;
    } else {
      return;
    }
    let assignToGroup: boolean = false;
    if (harvestId == null && groupId != null) {
      assignToGroup = true;
    }

    let diff: number = Timestamp.fromDate(start).seconds - event.startDateTime.seconds;

    let eventsToSave: models.Event[] = []

    switch (recurringDescission) {
      case 'following':
        let applicableFollowingEvents = await this.eventService.getRepeatingEvents(assignToGroup, parentId, event.repeatedEventId, event.startDateTime);
        for (let i = 0; i < applicableFollowingEvents.length; i++) {
          const ev = applicableFollowingEvents[i];
          ev.startDateTime = new Timestamp(ev.startDateTime.seconds + diff, ev.startDateTime.nanoseconds)
          eventsToSave.push(ev);
        }
        break;
      case 'all':
        let applicableEvents = await this.eventService.getRepeatingEvents(assignToGroup, parentId, event.repeatedEventId);
        for (let i = 0; i < applicableEvents.length; i++) {
          const ev = applicableEvents[i];
          ev.startDateTime = new Timestamp(ev.startDateTime.seconds + diff, ev.startDateTime.nanoseconds)
          eventsToSave.push(ev);
        }
        break;
      case 'one':
      default:
        event.startDateTime = Timestamp.fromDate(start);
        eventsToSave.push(event);
        break;
    }

    await this.eventService.saveListSplit(assignToGroup, eventsToSave);

    this.notifierService.success('Successfully saved new task date');
  }

  deletedHarvestsAndGroups(result: any) {
    let harvestIdsToRemove: string[] = result.harvestIds;
    let groupIdsToRemove: string[] = result.groupIds;

    this.filterResponse.harvestIds = this.filterResponse.harvestIds.filter(i => !harvestIdsToRemove.includes(i))
    this.filterResponse.groupIds = this.filterResponse.groupIds.filter(i => !groupIdsToRemove.includes(i))
    //need to get new group in filter
    this.filterSectionComponent.harvestsChanged(this.filterResponse.harvestIds);
    this.filterSectionComponent.groupsChanged(this.filterResponse.groupIds);
  }

  eventOrder(eventA, eventB) {
    if (eventA.eventObj.highPriority && !eventB.eventObj.highPriority) {
      return -1;
    }
    if (!eventA.eventObj.highPriority && eventB.eventObj.highPriority) {
      return 1;
    }

    if (eventA.allDay && !eventB.allDay) {
      return 1;
    }
    if (!eventA.allDay && eventB.allDay) {
      return -1;
    }

    if (eventA.start < eventB.start) {
      return -1;
    } else if (eventA.start > eventB.start) {
      return 1;
    }

    let precA: number = eventA.precedence ? eventA.precedence : 0;
    let precB: number = eventB.precedence ? eventB.precedence : 0;

    if (precB < precA) {
      return -1;
    } else if (precB > precA) {
      return 1;
    }

    eventB.title.localeCompare(eventA.title)

    if (eventA.title < eventB.title) { return -1; }
    if (eventA.title > eventB.title) { return 1; }

    return 0;
  }

  eventClassName(arg: any) {
    let c = [];
    let event: EventDef = arg.event;
    let eventObj: models.Event = event.extendedProps.eventObj;
    if (eventObj.highPriority) {
      c.push('high-priority');
    }
    if (eventObj.completed) {
      c.push('completed');
    }

    let applicableEvents = eventObj.discussions?.filter(i =>
      i.discussionType == models.DiscussionType.Note ||
      i.discussionType == models.DiscussionType.InitialNote ||
      i.discussionType == models.DiscussionType.AttachedFile) ?? [];

    let totalBadge = applicableEvents.length + (eventObj.cloudAttachments?.length ?? 0);

    if (totalBadge > 0) {
      c.push('cal-badge');

      switch (totalBadge) {
        case (1):
          c.push('one');
          break;
        case (2):
          c.push('two');
          break;
        case (3):
          c.push('three');
          break;
        case (4):
          c.push('four');
          break;
        case (5):
          c.push('five');
          break;
        case (6):
          c.push('six');
          break;
        case (7):
          c.push('seven');
          break;
        case (8):
          c.push('eight');
          break;
        case (9):
          c.push('nine');
          break;
        default:
          c.push('plus');
          break;
      }
    }
    return c;
  }
}
