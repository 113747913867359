//import { DataCollectionDefinitionUnit } from "app/viewmodels";

export interface DbDataLibrary {
    list?: Array<DataCollectionDefinition>;
}

export interface DataCollectionDefinition {
    id?: string;
    name: string;
    unit: string;
}
