<h2 mat-dialog-title>Bulk Assign Events</h2>
<mat-dialog-content>
  <div class="row">
    <div class="col-sm-6">
        <app-team-select-multiple
            [(teamIds)]="selectedTeamIds">
        </app-team-select-multiple>
        <mat-checkbox [(ngModel)]="keepAllOtherTeams">Keep Existing Teams Assigned</mat-checkbox>
    </div>
    <div class="col-sm-6">
        <app-user-select-multiple
            [(userIds)]="selectedUserIds">
        </app-user-select-multiple>
        <mat-checkbox [(ngModel)]="keepAllOtherUsers">Keep Existing Users Assigned</mat-checkbox>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button (click)="okClick()">Ok</button>
</mat-dialog-actions>
