
<div class="bottom-bar" *ngIf="display">
    <div class="row">
        <div class="col-sm-3">

        </div>
        <div class="col-sm-6">
            <span style="font-size: 20px;"><b>Bulk Edit Enabled.</b> {{bulkEditWarning}}</span>
        </div>
        <div class="col-sm-3" style="float: right">
            <button mat-raised-button class="gridbtn" [disabled]="saving" (click)="saveBulkEdit()">
                <mat-icon *ngIf="!saving">save</mat-icon>
                <mat-icon *ngIf="saving">
                    <mat-spinner matSuffix diameter="20"></mat-spinner>
                </mat-icon>
            </button>
            <button mat-raised-button class="gridbtn" [disabled]="saving" (click)="cancelBulkEdit()">
                <mat-icon>undo</mat-icon>
            </button>
        </div>
    </div>
</div>
