import { ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";

import * as viewmodels from "app/viewmodels";
import * as models from "app/models";
import * as services from "app/services";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { DataInputDialogComponent } from "../data-input-dialog/data-input-dialog.component";
import { FormControl } from "@angular/forms";
import { ReplaySubject, Subject } from "rxjs";

@Component({
  selector: "app-data-submission-dialog",
  templateUrl: "./data-submission-dialog.component.html",
  styleUrls: ["./data-submission-dialog.component.scss"],
})
export class DataSubmissionDialogComponent implements OnInit {
  dataSubmissions!: models.DataSubmission[];
  dataCollectionDefinitions: Array<models.DataCollectionDefinition> = [];
  dataSubmissionDisplays: DataSubmissionDisplay[] = [];

  clonedSubmissions: { [s: string]: models.DataSubmission } = {};

  users: models.User[] = [];

  unitList: viewmodels.DataCollectionDefinitionUnit[] = [];

  get canAddSubmission() {
    return true;
    //return this.dataSubmissionDisplays.length < this.users.length+1;
  }

  get currentSelectedUserIds() {
    return this.dataSubmissionDisplays.map((i) => i.dataSubmission.userId);
  }


  constructor(
    private dialogRef: MatDialogRef<DataSubmissionDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: viewmodels.DataSubmissionDialogRequest,
    private userService: services.UserService,
    private helperService: services.HelperService,
    private notifierService: services.NotifierService,
    private firestore: AngularFirestore,
    private claimsService: services.ClaimsService,
    private dialog: MatDialog,
    private cdRef: ChangeDetectorRef
  ) {
    if (data) {
      this.dataSubmissions = this.helperService.deepCopy(data.dataSubmissions);
      this.dataCollectionDefinitions = data.dataCollectionDefinitions;
    }
  }

  ngOnInit(): void {
    this.unitList = new viewmodels.DataCollectionDefinitionUnits().list;

    this.fillDisplay();
    this.retrieveUsers();
  }

  populateUnits() {
    for (let i = 0; i < this.dataSubmissionDisplays.length; i++) {

    }
  }

  getUnitViewModel(unitId: string) {
    return this.unitList.find((i) => i.id == unitId);
  }

  getSelectedUsersName(userId: string) {
    let user = this.users.find((i) => i.id == userId);
    if (user) {
      return user.displayName;
    }
    return "";
  }

  async retrieveUsers() {
    const companyId: string = this.helperService.currentCompanyId;
    this.users = await this.userService.getUsersForCompanyWithCaching(
      companyId,
      true,
      true
    );
  }

  onRowEditInit(submissionDisplay: DataSubmissionDisplay) {
    submissionDisplay.editing = true;
    this.clonedSubmissions[submissionDisplay.dataSubmission.id as string] = { ...this.helperService.deepCopy(submissionDisplay.dataSubmission) };
  }

  onRowDelete(submissionDisplay: DataSubmissionDisplay) {
    this.dataSubmissionDisplays = this.dataSubmissionDisplays.filter(
      (i) => i.dataSubmission.id != submissionDisplay.dataSubmission.id
    );
    delete this.clonedSubmissions[submissionDisplay.dataSubmission.id as string];
  }

  onRowEditSave(submissionDisplay: DataSubmissionDisplay) {
    if(submissionDisplay.dataSubmission.userId == null){
      this.notifierService.error("User is required");
      return;
    }
    if (submissionDisplay.dataSubmission.userId != null) {
      delete this.clonedSubmissions[submissionDisplay.dataSubmission.id as string];
    } else {
      this.notifierService.error("User is required");
    }
    submissionDisplay.editing = false;
  }

  onRowEditCancel(submissionDisplay: DataSubmissionDisplay, index: number) {
    this.dataSubmissionDisplays[index].dataSubmission =
      this.clonedSubmissions[submissionDisplay.dataSubmission.id as string];
    delete this.clonedSubmissions[submissionDisplay.dataSubmission.id as string];
    submissionDisplay.editing = false;

    if(this.dataSubmissionDisplays[index].dataSubmission.userId == null){
      //remove the submission
      this.dataSubmissionDisplays.splice(index, 1);
      return;
    }
  }

  add() {
    let currentUserId = this.claimsService.currentUserId();
    // if(this.dataSubmissionDisplays.some(i => i.dataSubmission.userId == currentUserId)){
    //   currentUserId = null;
    // }
    if(this.users.find(i => i.id == currentUserId) == null){
      currentUserId = null;
    }

    this.dataSubmissionDisplays.push({
      editing: true,
      dataSubmission: {
        id: this.firestore.createId(),
        userId: currentUserId,
        data: {},
      }
    });
    this.onRowEditInit(this.dataSubmissionDisplays[this.dataSubmissionDisplays.length - 1]);
  }

  close() {
    this.dialogRef.close(null);
  }

  save() {
    if(this.dataSubmissionDisplays.some(i => i.editing)) {
      this.notifierService.error("Please save or cancel all edits before saving");
      return;
    }
    this.extractSubmissions();
    this.dialogRef.close(this.dataSubmissions);
  }

  fillDisplay() {
    this.dataSubmissionDisplays = this.dataSubmissions.map((i) => {
      return {
        editing: false,
        dataSubmission: i,
      };
    });
  }

  extractSubmissions() {
    this.dataSubmissions = this.dataSubmissionDisplays.map((i) => {
      return i.dataSubmission;
    });
  }

  increment(submission: DataSubmissionDisplay, def: models.DataCollectionDefinition) {
    let inObject: any = {
      title: def.name,
    }

    let dialog = this.dialog.open(DataInputDialogComponent, {
      data: inObject
    });

    dialog.afterClosed().subscribe(data => {
      if (data) {
        let currentData = submission.dataSubmission.data[def.id] ?? 0;
        submission.dataSubmission.data[def.id] = currentData + data;
      }
    })
  }

  getClass(submission: DataSubmissionDisplay) {
    let index = this.dataSubmissionDisplays.indexOf(submission);

    if(submission.dataSubmission.userId == null) {
      return '';
    }

    let previous;
    if(index == 0){
      previous = null;
    } else {
      previous = this.dataSubmissionDisplays[index-1];
    }

    let next;
    if(index == this.dataSubmissionDisplays.length-1){
      next = null;
    } else {
      next = this.dataSubmissionDisplays[index+1];
    }
    if((previous?.dataSubmission.userId != submission.dataSubmission.userId) && next != null && next?.dataSubmission.userId == submission.dataSubmission.userId){
      return "first";
    }
    if(previous?.dataSubmission.userId == submission.dataSubmission.userId && next?.dataSubmission.userId != submission.dataSubmission.userId){
      return "last";
    }

    return '';
  }

  userChange() {
    //reorder the submissions
    this.dataSubmissionDisplays = this.dataSubmissionDisplays.sort((a, b) => {
      if(a.dataSubmission.userId == 'not_specified') {
        return -1;
      }
      let userA = this.users.find(i => i.id == a.dataSubmission.userId)?.displayName?.toLowerCase();
      let userB = this.users.find(i => i.id == b.dataSubmission.userId)?.displayName?.toLowerCase();
      if (!userA || !userB) {
        return 0; // Handle cases where a user is not found
      }
    
      if (userA < userB) {
        return -1;
      } else if (userA > userB) {
        return 1;
      }
    
      // If display names are equal, use additional criteria or return 0
      return 0;
    });
    // Refresh the table view
    this.cdRef.detectChanges();
  }
}

interface DataSubmissionDisplay {
  editing: boolean;
  dataSubmission: models.DataSubmission;
}