import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

import * as models from 'app/models';
import * as services from 'app/services';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-templated-task-select',
  templateUrl: './templated-task-select.component.html',
  styleUrls: ['./templated-task-select.component.scss']
})
export class TemplatedTaskSelectComponent implements OnInit {

  templatedTasks: models.TemplatedTask[] = [];

  taskValue: models.TemplatedTask;
  @Input()
  get selectedTask() {
    return this.taskValue;
  }
  @Output() selectedTaskChange = new EventEmitter();
  set selectedTask(val: models.TemplatedTask) {
    this.taskValue = val;
    this.selectedTaskChange.emit(this.taskValue);
  }

  public taskCtrl: FormControl = new FormControl();
  public taskFilterCtrl: FormControl = new FormControl();
  public filteredTasks: ReplaySubject<models.Group[]> = new ReplaySubject<models.Group[]>(1);
  protected _onDestroy = new Subject<void>();

  protected filterTasks() {
    if (!this.templatedTasks) {
      return;
    }
    // get the search keyword
    let search = this.taskFilterCtrl.value;
    if (!search) {
      this.filteredTasks.next(this.templatedTasks.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the Tasks
    let filtered = this.templatedTasks.filter(group => {
      return (group.name != null && group.name.toLowerCase().indexOf(search) > -1)
    })
    this.filteredTasks.next(filtered);
  }
  
  constructor(
    private templatedTaskService: services.TemplatedTasksService
    , private helperService: services.HelperService
  ) { }

  ngOnInit(): void {
    this.load();

    // listen for search field value changes
    this.taskFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterTasks();
      });
  }

  async load() {
    let response = await this.templatedTaskService.getAll();
    this.templatedTasks = this.helperService.sortArrayByStringField(response, 'name');
    this.filterTasks();
  }

  redirect(event: Event) {
    event.stopPropagation();
    window.open('/console/templates/task-library?tt=' + this.selectedTask.id, '_blank');
  }

}
