import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import { SessionStorageService } from 'angular-web-storage';

//import { AngularFireDatabase, AngularFireList } from '@angular/fire/database'
import { AngularFirestore, AngularFirestoreCollection, DocumentData, Query } from '@angular/fire/compat/firestore';
import { QuerySnapshot } from 'firebase/firestore';
import { Observable } from 'rxjs';
import * as models from '../models';
import * as viewModels from '../viewmodels';
import { HelperService } from './helper.service';
import { Functions, HttpsCallableResult, httpsCallable } from '@angular/fire/functions';

@Injectable()
export class TemplateService {

  companyRef: AngularFirestoreCollection;

  slug: string = 'templates';

  constructor(
    private firestore: AngularFirestore,
    private helperService: HelperService,
    private storage: SessionStorageService,
    private functions: Functions
  ) {
    console.log('Hello UserService Provider');
    this.companyRef = this.firestore.collection('companies');
  }

  async getNumberOfTemplates(): Promise<number> {

    let companyId = this.helperService.currentCompanyId;
    
    let templateResponse = await this.companyRef.doc(companyId).collection(this.slug).get().toPromise();
    return templateResponse.size;
  }

  async getNumberOfTemplatesForCompany(companyId: string): Promise<number> {
    let templateResponse = await this.companyRef.doc(companyId).collection(this.slug).get().toPromise();
    return templateResponse.size;
  }

  async getPhasesForAllTemplates() {
    let companyId = this.helperService.currentCompanyId;
    let templateResponse = await this.companyRef.doc(companyId).collection(this.slug).get().toPromise();
    let preCutPhases: Array<string> = []
    let postCutPhases: Array<string> = []
    templateResponse.forEach(doc => {
      let template: models.Template = doc.data();

      let cutdownOccured: boolean = false;
      template.phases.forEach(phase => {

        if(preCutPhases.includes(phase.name) || postCutPhases.includes(phase.name)){
          return;
        }

        if(cutdownOccured){
          postCutPhases.push(phase.name)
        } else {
          preCutPhases.push(phase.name)
        }

        if(phase.harvestAtEnd){
          cutdownOccured = true;
        }
      });
    })
    return {
      preCutPhases: preCutPhases,
      postCutPhases: postCutPhases
    };
  }

  async get(templateId: string): Promise<models.Template> {
    let companyId = this.helperService.currentCompanyId;

    let templateResponse = await this.companyRef.doc(companyId).collection(this.slug).doc(templateId).get().toPromise()
    let template: models.Template = templateResponse.data();
    template.uid = templateResponse.id;

    return template;
  }

  async getFirst(): Promise<models.Template> {
    let companyId = this.helperService.currentCompanyId;

    let templateResponse = await this.companyRef.doc(companyId).collection(this.slug).ref.orderBy('name').limit(1).get();

    if(templateResponse.empty){
      return null;
    }

    let template: models.Template = templateResponse.docs[0].data();
    template.uid = templateResponse.docs[0].id;

    return template;
  }

  async getAll(onlySecondaryTemplates: boolean = false): Promise<Array<models.Template>> {
    let returnList: Array<models.Template> = [];

    let companyId = this.helperService.currentCompanyId;
    let templateResponse = await this.companyRef.doc(companyId).collection(this.slug, ref => {
      let retRef: Query<DocumentData> = ref;
      if(onlySecondaryTemplates) {
        retRef = ref.where('type', '==', models.TemplateType.Secondary)
      } else {
        retRef = ref;
      }
      return retRef
    }).get().toPromise();

    for (let i = 0; i < templateResponse.docs.length; i++) {
        const element = templateResponse.docs[i];
        
        let template: models.Template = element.data();
        template.uid = element.id;
        // template.phases = [];
        template.phases = this.helperService.sortDesc("sortOrder", template.phases)
        // template.phases = template.phases.sort((obj1, obj2) => {
        //     if (obj1.sortOrder > obj2.sortOrder) {
        //         return 1;
        //     }
        
        //     if (obj1.sortOrder < obj2.sortOrder) {
        //         return -1;
        //     }
        
        //     return 0;
        // });
        
        template.phases.forEach(phase => {
            phase.steps = this.helperService.sortDesc("day", phase.steps);
            phase.steps.forEach(step => {
              if(!step.precedence){
                step.precedence = 0;
              }
              if(step.anyTime === undefined){
                step.anyTime = true;
              }
            });
            // phase.steps = phase.steps.sort((obj1, obj2) => {
            //     if (obj1.day > obj2.day) {
            //         return 1;
            //     }
            
            //     if (obj1.day < obj2.day) {
            //         return -1;
            //     }
            
            //     return 0;
            // });
        });

        //IF EVER USING SUB COLLECTIONS
        
        // let phaseResponse = await this.firestore.collection('templates').doc(template.uid).collection('phases').get().toPromise();

        // var phases = phaseResponse.docs.sort((obj1, obj2) => {
        //     if (obj1.data().sortOrder > obj2.data().sortOrder) {
        //         return 1;
        //     }
        
        //     if (obj1.data().sortOrder < obj2.data().sortOrder) {
        //         return -1;
        //     }
        
        //     return 0;
        // });

        // for (let p = 0; p < phases.length; p++) {
        //     const element = phases[p];
        //     let phase: models.Phase = element.data();
        //     phase.uid = element.id;
        //     phase.steps = [];
            
        //     let stepResponse = await this.firestore.collection('templates').doc(template.uid).collection('phases').doc(phase.uid).collection('steps').get().toPromise();
          
        //     var stages = stepResponse.docs.sort((obj1, obj2) => {
        //         if (obj1.data().day > obj2.data().day) {
        //             return 1;
        //         }
            
        //         if (obj1.data().day < obj2.data().day) {
        //             return -1;
        //         }
            
        //         return 0;
        //     });
          
        //     stages.forEach(st => {
        //         let step: models.Step = st.data();
        //         step.uid = st.id;
        //         phase.steps.push(step);
        //     });
            
        //     template.phases.push(phase);
        // }
        returnList.push(template);
    }
    return returnList;
  }

  async save(template: models.Template): Promise<any> {

    let uid: string;

    template.phases?.forEach(phase => {
      if(phase.compliancePhase == undefined){
        delete phase.compliancePhase
      }
      phase.steps?.forEach(step => {
        if(step.uid == null || step.uid == "") {
          step.uid = this.firestore.createId()
        };
        if(step.dayTotal){
          delete step.dayTotal;
        }
        if(step.dayOfWeek){
          delete step.dayOfWeek
        }
      })
    })

    if(template.uid == null || template.uid == ""){
      //template.dateAdded = firebase.firestore.Timestamp.fromMillis(Date.now());
      uid = this.firestore.createId();
    }else{
      uid = template.uid;
    }

    

    delete template.uid;

    this.removeEmptyValues(template);

    let companyId = this.helperService.currentCompanyId;
    
    await this.companyRef.doc(companyId).collection("templates").doc(uid).set(template);
    return uid;
  }

  async createFromHarvest(harvestId: string, newTemplateName: string) {
    let companyId = this.helperService.currentCompanyId;

    let inObj = {
      companyId: companyId,
      harvestId: harvestId,
      templateName: newTemplateName
    }

    let createTemplateFromHarvestMethod = httpsCallable(this.functions, 'createTemplateFromHarvest');
    var createTemplateFromHarvestResponse = await createTemplateFromHarvestMethod(inObj);

    return createTemplateFromHarvestResponse
  }

  delete(template: models.Template): Promise<void> {
    let companyId = this.helperService.currentCompanyId;
    
    return this.companyRef.doc(companyId).collection('templates').doc(template.uid).delete();
  }

  async copy(templateId: string, name: string) : Promise<HttpsCallableResult> {
    let companyId = this.helperService.currentCompanyId;

    let copyTemplateMethod = httpsCallable(this.functions, 'copyTemplate');
    var copyTemplateResponse = await copyTemplateMethod({ companyId: companyId, templateId: templateId, name: name });

    return copyTemplateResponse
  }

  async import(name: string, type: string, importRows: Array<viewModels.TemplateExport>): Promise<string> {

    let companyId = this.helperService.currentCompanyId;

    let phases: Array<models.Phase> = [];

    importRows.forEach(importRow => {
      let currentPhase = phases.find(i => i.name == importRow.phase);

      let step: models.Step = {
        day: importRow.dayInPhase,
        description: importRow.taskDescription,
        //precedence?: number;
        highPriority: importRow.highPriority,
        anyTime: importRow.anytime,
        startTime: importRow.startTime,
        uid: this.firestore.createId(),
      }

      if(importRow.cloudAttachments != null && importRow.cloudAttachments != '') {
        step.cloudAttachments = importRow.cloudAttachments.split('|')
      }
      if(importRow.notes != null && importRow.notes != '') {
        step.notes = importRow.notes.split('|')
      }
      if(importRow.tags != null && importRow.tags != '') {
        step.tags = importRow.tags.split('|')
      }
      if(importRow.subtasks != null && importRow.subtasks != '') {
        step.subtasks = JSON.parse(importRow.subtasks)
      }
      if(importRow.dataCollectionDefinitions != null && importRow.dataCollectionDefinitions != '') {
        step.dataCollectionDefinitions = JSON.parse(importRow.dataCollectionDefinitions)
      }

      if(currentPhase == null){
        let newPhase: models.Phase = {
          name: importRow.phase,
          daysSinceLastPhase: +importRow.daysSinceLastPhase,
          steps: [
            step
          ]
        }
        phases.push(newPhase);
      }else {
        currentPhase.steps.push(step)
      }
    });

    let template: models.Template = {
      name: name,
      phases: phases,
      type: type
    }
    
    let templateResponse = await this.companyRef.doc(companyId).collection('templates').add(template);

    return templateResponse.id;
  }

  private removeEmptyValues(template: models.Template){
    template.phases.forEach(phase => {
      phase.steps.forEach(step => {
        if(step.tags == null){
          delete step.tags;
        }
      })
    })
  }

  setDayTotal(template: models.Template) {
    template.phases.forEach(phase => {
      phase.steps.forEach(step => {
        step.dayTotal = this.calcTotalDay(template, phase, step);
      });
    });
  }

  calcTotalDay(template: models.Template, phase: models.Phase, step:models.Step) {

    if(phase == null || step == null){
      return '';
    }

    let phaseIndex = template.phases.findIndex(i => i.name == phase.name);

    if(phaseIndex == 0 || step.day == null){
      return step.day;
    }

    let seperator: string = ',';
    if(step.day.includes('-')){
      seperator = '-';
    }

    let arrayDay = step.day.split('-').join(',').split(',');

    let total: number = 0;

    for (let i = 0; i < phaseIndex; i++) {
      const phase = template.phases[i];
      let allStepNumbers = phase.steps.map(i => i.day).join('-').split('-').join(',').split(',').map(i => +i);
      let sorted = allStepNumbers.sort((a, b) => { return a-b });
      let largest: number = 0;
      if(sorted.length > 0){
        largest = sorted[sorted.length-1];
      }
      total = total + phase.daysSinceLastPhase + largest;

      // let lastStep = phase.steps[phase.steps.length - 1];

      // if(lastStep != null){
      //   let lastArray = lastStep.day.split('-').join(',').split(',');
      //   total = total + phase.daysSinceLastPhase + parseInt(lastArray[lastArray.length - 1]);
      // }
    }
    
    let retArray = [];
    for (let j = 0; j < arrayDay.length; j++) {
      let dayElement = arrayDay[j];
      let parse = parseInt(dayElement);
      if(parse != null){
        dayElement = (phase.daysSinceLastPhase + total + parse).toString();
      }

      retArray.push(dayElement);
    }

    return retArray.join(seperator);
  }

  recalcDay(template: models.Template, phase: models.Phase, step:models.Step){
    let startDay: number = 0;
    let phaseIndex = template.phases.indexOf(phase);
    for (let i = 0; i < phaseIndex; i++) {
      const p = template.phases[i];
      let lastStep = p.steps[p.steps.length - 1];
      if(lastStep != null){
        let lastArray = lastStep.day.split('-').join(',').split(',');
        startDay += p.daysSinceLastPhase + parseInt(lastArray[lastArray.length - 1])
      }
    };

    let arrayDay = step.dayTotal.split('-').join(',').split(',');
    let retArray = [];
    for (let j = 0; j < arrayDay.length; j++) {
      let dayElement = arrayDay[j];
      let parse = parseInt(dayElement);
      if(parse != null){
        let numb = (parse - (phase.daysSinceLastPhase + startDay));
        if(numb < 0){
          numb = 0;
        }
        dayElement = numb.toString();
      }

      retArray.push(dayElement);
    }

    let seperator: string = ',';
    if(step.dayTotal.includes('-')){
      seperator = '-';
    }

    return retArray.join(seperator);
    //step.day = (+step.dayTotal - startDay).toString();

  }

  setDayOfWeek(template: models.Template, startDay: string) {
    let startIndex = models.Contstants.daysOfWeek.indexOf(startDay);
    template.phases.forEach(phase => {
      phase.steps.forEach(step => {
        if(startDay == null){
          if(step.dayOfWeek){
            delete step.dayOfWeek;
          }
        } else {
          if(step.dayTotal.includes('-')) {
            let range = step.dayTotal.split('-');
            step.dayOfWeek = this.getDayOfWeekStringFromList(range, startIndex, '-');
          } else if(step.dayTotal.includes(',')){
            let list = step.dayTotal.split(',');
            step.dayOfWeek = this.getDayOfWeekStringFromList(list, startIndex, ',');
          } else {
            step.dayOfWeek = this.getDayOfWeekStringFromList([step.dayTotal], startIndex, ',');
          }
        }
      });
    });
  }

  getDayOfWeekStringFromList(list: Array<string>, startIndex: number, sep: string): string{
    let dayStrings: Array<string> = [];
    list.forEach(element => {
      let num: number = +element;
      let remainder = (num + startIndex) % models.Contstants.daysOfWeek.length;
      dayStrings.push(models.Contstants.daysOfWeek[remainder]);
    });

    return dayStrings.join(sep);
  }

  async removeReferenceToAttachment(attachmentId: string) {
    let companyId = this.helperService.currentCompanyId;
    let templatesSS = await this.companyRef.doc(companyId).collection(this.slug).get().toPromise()
    templatesSS.docs.forEach(async doc => {
      let template: models.Template = doc.data();
      let allAttachments = template.phases.flatMap(i => i.steps).flatMap(i => i.attachments);

      if(!allAttachments.includes(attachmentId)) {
        return;
      }
      template.phases.forEach(phase => {
        phase.steps.forEach(step => {
          if(step.attachments != null){
            step.attachments = step.attachments.filter(i => i != attachmentId);
          }
        });
      });
      await doc.ref.update(template);
    });
  }

  async applyWorkflow(requestObject: models.ApplyWorkflowRequest) {
    let applyWorkflowMethod = httpsCallable<any, models.Result<any>>(this.functions, 'applyWorkflow');;
    let applyWorkflowResponse = await applyWorkflowMethod(requestObject);
    return applyWorkflowResponse
  }

  async updateStepsFromTemplatedTask(requestObject: models.UpdateStepsFromTemplatedTaskRequest) {
    let method = httpsCallable<any, models.Result<models.Template[]>>(this.functions, 'updateStepsFromTemplatedTask');;
    let response = await method(requestObject);
    return response
  }
}
