import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AgGridModule } from 'ag-grid-angular';
import { MaterialModule } from './app.material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PipeModule } from './pipes/pipe.module';

import { 
  MatCheckboxComponent,
  MatInputComponent,
  MatRadioComponent,
  MatSelectComponent,
  MatButtonComponent,
  MatIconComponent,
  RoleComponent,
  UpdateRoleBtnComponent,
  TimeStampComponent,
  ListComponent,
  HighPriortyIconComponent,
  MomentComponent,
  CheckboxLabelComponent,
  ValueEditButtonComponent,
  TagComponent,
  ProcessTypeCellComponent,
  ProcessStatusCellComponent,
  ActionButtonCellComponent,
  UnitCellComponent,
  LinkCellComponent,
  ProgressBarCellComponent,
  ZoneHierarchyComponent,
  IconComponent,
  IconMenuComponent,
  TemplateStepMoreComponent,
  TagEditorCell,
  TimeEditorCell,
  AnytimeCellComponent,
  DayEditorCell,
  NumberEditorCell,
  PhaseDateRangeCellComponent,
  TeamCellComponent,
  TeamSelectMultipleEditorCell,
  WarningComponent,
  EstimatedTimeEditorCell,
  TimeSpentCellComponent,
  UsernameAndImageComponent,
  DayOfWeekCellComponent,
  NameColorCellComponent,
  DateCellComponent,
  BatchLinkCellComponent,
  DutchieLinkComponent,
  PlantCountCellComponent,
  ButtonCellComponent
} from './components/cell-components';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    PipeModule,
    AgGridModule.withComponents([
      MatCheckboxComponent, 
      MatInputComponent, 
      MatRadioComponent, 
      MatSelectComponent, 
      MatButtonComponent, 
      MatIconComponent, 
      RoleComponent, 
      UpdateRoleBtnComponent, 
      TimeStampComponent,
      PhaseDateRangeCellComponent, 
      ListComponent, 
      MomentComponent, 
      ProcessTypeCellComponent,
      ProcessStatusCellComponent,
      ActionButtonCellComponent,
      ProgressBarCellComponent,
      ZoneHierarchyComponent,
      TagEditorCell,
      TimeEditorCell,
      AnytimeCellComponent,
      DayEditorCell,
      NumberEditorCell,
      TeamCellComponent,
      TeamSelectMultipleEditorCell,
      WarningComponent,
      EstimatedTimeEditorCell,
      DayOfWeekCellComponent,
      DateCellComponent,
      BatchLinkCellComponent,
      DutchieLinkComponent,
      PlantCountCellComponent
    ]),
  ],
  declarations: [
    MatCheckboxComponent,
    MatInputComponent,
    MatRadioComponent,
    MatSelectComponent,
    MatButtonComponent,
    MatIconComponent,
    RoleComponent,
    ListComponent,
    UpdateRoleBtnComponent,
    TimeStampComponent,
    HighPriortyIconComponent,
    MomentComponent,
    CheckboxLabelComponent,
    ValueEditButtonComponent,
    TagComponent,
    ProcessTypeCellComponent,
    ProcessStatusCellComponent,
    ActionButtonCellComponent,
    UnitCellComponent,
    LinkCellComponent,
    ProgressBarCellComponent,
    PhaseDateRangeCellComponent,
    ZoneHierarchyComponent,
    IconComponent,  
    IconMenuComponent,
    TemplateStepMoreComponent,
    TagEditorCell,
    TimeEditorCell,
    AnytimeCellComponent,
    DayEditorCell,
    NumberEditorCell,
    TeamCellComponent,
    TeamSelectMultipleEditorCell,
    WarningComponent,
    EstimatedTimeEditorCell,
    TimeSpentCellComponent,
    UsernameAndImageComponent,
    DayOfWeekCellComponent,
    NameColorCellComponent,
    DateCellComponent,
    BatchLinkCellComponent,
    DutchieLinkComponent,
    PlantCountCellComponent,
    ButtonCellComponent
  ],
  exports: [
    AgGridModule
  ]
})
export class GridModule { }
