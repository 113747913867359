import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";

import * as viewmodels from "../../viewmodels";

@Component({
    selector: "icon-menu-cell",
    template: `
    <button mat-icon-button class="badge-btn" [matMenuTriggerFor]="menu" [matBadge]="badge" [matBadgeHidden]="badge===0" style="width: 25px; height: 25px; line-height: 15px;">
        <mat-icon aria-hidden="false" style="vertical-align: middle">{{icon}}</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
        <button mat-menu-item *ngFor="let item of menuItems" (click)="item.action()">
            <mat-icon>{{item.icon}}</mat-icon>
            <span>{{item.label}}</span>
        </button>
    </mat-menu>
    `,
    styles: [
        `
        ::ng-deep .mat-menu-item.mat-badge-medium.mat-badge-above .mat-badge-content {
            top: 0px;
            right: 0px;
        }
        ::ng-deep .badge-btn.mat-badge-medium.mat-badge-above .mat-badge-content {
            top: -7px;
        }
        `
    ]
})
export class IconMenuComponent implements ICellRendererAngularComp {
    private params: any;

    icon: string;
    badge: number = 0;
    menuItems: viewmodels.MenuItem[];

    agInit(params: any): void {
        this.setData(params);
    }

    refresh(params: any): boolean {
        this.setData(params);
        return true;
    }

    setData(params: any){
        this.params = params;
        this.icon = this.params.icon;
        this.badge = this.params.badge;
        this.menuItems = this.params.menuItems;
    }
}