import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';

import * as models from 'app/models';
import * as services from 'app/services';
import * as viewmodels from 'app/viewmodels';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-data-collection-definitions-dialog',
  templateUrl: './data-collection-definitions-dialog.component.html',
  styleUrls: ['./data-collection-definitions-dialog.component.scss']
})
export class DataCollectionDefinitionsDialogComponent implements OnInit, OnDestroy {

  selectList: DataCollectionSelect[] = [];
  //dataCollectionDefinitions: Array<models.DataCollectionDefinition> = [];

  //unitList: viewmodels.DataCollectionDefinitionUnit[] = [];
  dataLibrary: models.DataCollectionDefinition[] = [];
  units: viewmodels.DataCollectionDefinitionUnit[] = [];

  get canSeeDataLibraryAdd() {
    return this.claimsService.userRole?.permissions?.includes(models.Permissions.viewGrowSettings);
  }

  dataLibrarySub: Subscription;

  constructor(
    private dialogRef: MatDialogRef<DataCollectionDefinitionsDialogComponent>
    , @Inject(MAT_DIALOG_DATA) public data: any
    , private helperService: services.HelperService
    , private notifierService: services.NotifierService
    , private firestore: AngularFirestore
    , private dataLibraryService: services.DataLibraryService
    , private claimsService: services.ClaimsService
  ) { 
    if(data) {
      let dataCollectionDefinitions =  this.helperService.deepCopy(data.dataCollectionDefinitions);
      this.selectList = dataCollectionDefinitions.map(i => {
        return {
          id: i.id,
          data: i
        }
      });
    }

    //this.unitList = this.helperService.sortArrayByStringField(new viewmodels.DataCollectionDefinitionUnits().list, 'display');
  }

  ngOnInit(): void {
    this.getDataLibrary();
    this.units = new viewmodels.DataCollectionDefinitionUnits().list;
  }

  ngOnDestroy(): void {
    if(this.dataLibrarySub) this.dataLibrarySub.unsubscribe();
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.selectList, event.previousIndex, event.currentIndex);
  }

  getUnit(unitId: string) {
    let unit = this.units.find(i => i.id == unitId);
    let retString = unit.display;
    if(unit.suffix) retString += ` (${unit.suffix})`;
    return retString;
  }

  async getDataLibrary() {
    this.dataLibrarySub = this.dataLibraryService.getAllSubscribe().subscribe(async (data) => {
      if(data) {
        this.dataLibrary = this.helperService.sortArrayByStringField(data.list, 'name');
      }
    });
    //this.dataLibrary = await this.dataLibraryService.getAll()
  }

  // drop(event: CdkDragDrop<string[]>) {
  //   moveItemInArray(this.dataCollectionDefinitions, event.previousIndex, event.currentIndex);
  // }

  add() {
    this.selectList.push({
      id: null,
      data: null
    });
  }

  remove(n: number){
    this.selectList.splice(n, 1);
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    //if(!this.verify()) return;
    this.selectList.forEach(item => {
      item.data = this.dataLibrary.find(i => i.id == item.id);
    });
    let dataCollectionDefinitions = this.selectList.filter(i => i.data != null).map(i => i.data);

    this.dialogRef.close(dataCollectionDefinitions);
  }

  // verify() {
  //   let empty = this.selectList.filter(i => i.data == null);

  //   if(empty.length > 0) {
  //     this.notifierService.error('Please fill out all data collection definitions');
  //     return false;
  //   }

  //   return true
  // }

  redirectToDataLibrary() {
    window.open('/console/grow-settings/data-library', '_blank');

      // await this.helperService.wait(100);
      // let addRows = this.selectList.filter(i => i.id == 'add')
      // addRows.forEach(row => {
      //   row.id = null;
      // });
  }

}

interface DataCollectionSelect {
  id: string;
  data?: models.DataCollectionDefinition;
}