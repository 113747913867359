
<mat-form-field appearance="outline" class="table-editor">
    <!-- <mat-label>User</mat-label> -->
    <mat-select [(ngModel)]="userId">
        <mat-option>
            <ngx-mat-select-search [formControl]="userFilterCtrl"
                                    placeholderLabel="Find User..."
                                    noEntriesFoundLabel="no matching user found"></ngx-mat-select-search>
        </mat-option>
        <mat-option [value]="user.id" *ngFor="let user of filteredUsers | async">{{user.displayName}}</mat-option>
        <!-- <mat-option *ngFor="let user of getUsers(submission.dataSubmission.userId)" [value]="user.id">{{user.displayName}}</mat-option> -->
    </mat-select>
</mat-form-field>
