import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CellValueChangedEvent, ColDef, GridOptions } from 'ag-grid-community';
import { DeviceDetectorService } from 'ngx-device-detector';

import * as models from 'app/models';
import * as services from 'app/services';
import * as viewmodels from 'app/viewmodels';
import { AnytimeCellComponent, ButtonCellComponent, DayEditorCell, EstimatedTimeEditorCell, IconComponent, IconMenuComponent, MatCheckboxComponent, NumberEditorCell, TagComponent, TagEditorCell, TeamCellComponent, TeamSelectMultipleEditorCell, TemplateStepMoreComponent, TimeEditorCell, TimeSpentCellComponent } from 'app/components/cell-components';
import { MatDialog } from '@angular/material/dialog';
import { AttachmentsSelectDialogComponent, DataCollectionDefinitionsDialogComponent, NotesComponent, SubtasksDialogComponent, TemplatedTaskCreateFromStepDialogComponent } from 'app/dialogs';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-template-step-list',
  templateUrl: './template-step-list.component.html',
  styleUrls: ['./template-step-list.component.scss']
})
export class TemplateStepListComponent implements OnInit, AfterViewInit, OnDestroy {

  sub: Subscription;

  @Input() allTags: Array<string> = [];
  @Input() template: models.Template;
  @Input() phase: models.Phase;

  //@Input() visible: boolean = false;
  visibleValue: boolean = false;
  @Input()
  get visible() {
    return this.visibleValue;
  }
  set visible(val) {
    this.hasBeenVisible = this.hasBeenVisible || val;
    this.visibleValue = val;
  }
  hasBeenVisible: boolean = false;

  stepsValue: Array<models.Step>;
  @Input()
  get steps() {
    return this.stepsValue;
  }
  @Output() stepsChange = new EventEmitter();
  set steps(val) {
    if (val == null) {
      val = [];
    }
    this.stepsValue = val;
    this.stepsChange.emit(this.stepsValue);
    this.resizeColumns();
  }

  //@Output() recalcDayTotals = new EventEmitter();
  @Output() replant = new EventEmitter();

  public gridOptions: GridOptions;

  private teams: models.Team[] = [];
  loadedTeams: boolean = false;

  // anyTime?: boolean;
  // startTime?: string;
  // cloudAttachments?: Array<string>;
  // notes?: Array<string>;
  // tags?: Array<string>;
  private possibleColumns: Array<ColDef> = [
    {
      headerName: "Day of Week",
      field: "dayOfWeek",
    },
    {
      headerName: "Day (Phase)",
      field: "day",
      editable: true,
      cellEditorFramework: DayEditorCell
    },
    {
      headerName: "Event Description",
      field: "description",
      editable: true,
      width: 300
    },
    {
      headerName: "Priority",
      field: "highPriority",
      cellRendererFramework: MatCheckboxComponent,
      cellRendererParams: (params) => {
        params.label = 'High Priority';
        return params;
      },
      width: 300
    },
    {
      headerName: "Time",
      field: "anyTime",
      editable: true,
      cellRendererFramework: AnytimeCellComponent,
      cellEditorFramework: TimeEditorCell,
      // cellEditorParams: (params) => {
      //   params.allTags = this.allTags
      //   return params;
      // },
    },
    {
      headerName: "Day (Total Harvest)",
      field: "dayTotal",
      editable: true,
      cellEditorFramework: DayEditorCell
    },
    // {
    //   headerName: "Cloud Attachments",
    //   field: "cloudAttachments",
    //   cellRendererFramework: IconComponent,
    //   cellRendererParams: (params) => {
    //     params.icon = 'cloud';
    //     params.badge = params.data.cloudAttachments?.length ?? 0;
    //     params.func = this.cloudAttachments.bind(this);
    //     return params;
    //   },
    // },
    // {
    //   headerName: "Notes",
    //   field: "notes",
    //   cellRendererFramework: IconComponent,
    //   cellRendererParams: (params) => {
    //     params.icon = 'sticky_note_2';
    //     params.badge = params.data.notes?.length ?? 0;
    //     params.func = this.notes.bind(this);
    //     return params;
    //   },
    // },
    {
      headerName: "Est. Time",
      field: "estimatedTime",
      editable: true,
      cellRendererFramework: TimeSpentCellComponent,
      cellEditorFramework: EstimatedTimeEditorCell,
      // cellEditorParams: (params) => {
      //   params.allTags = this.allTags
      //   return params;
      // },
    },
    {
      headerName: "Subtasks",
      field: "subtasks",
      //editable: true,
      cellRendererFramework: ButtonCellComponent,
      cellRendererParams: (params) => {
        params.icon = 'event_list';
        params.badge = params.data.subtasks?.length ?? 0;
        params.clickFunc = () => {
          const doc = document.documentElement;
          const top = (window.scrollX || doc.scrollTop) - (doc.clientTop || 0);
          const left = (window.scrollY || doc.scrollLeft) - (doc.clientLeft || 0);
          if(top != 0 || left != 0){
            window.scrollTo(0, 0);
          }
          let notesDialog = this.dialog.open(SubtasksDialogComponent, {
            data: {
              subtasks: params.data.subtasks || []
            }
          });
      
          notesDialog.afterClosed().subscribe(data => {
            if (data?.accept) {
              params.data.subtasks = data.subtasks;
              this.gridOptions.api.redrawRows();
            }
            if(top != 0 || left != 0){
              window.scrollTo({ top: top, left: left, behavior: 'auto' });
            }
          })
        };

        return params;
      }
    },
    {
      headerName: "Data",
      field: "description",
      cellRendererFramework: IconComponent,
      cellRendererParams: (params) => {
        params.icon = 'query_stats';
        params.badge = params.data.dataCollectionDefinitions?.length ?? 0;
        params.func = this.dataCollectionDefinitionClick.bind(this, params.data);
        return params;
      },
    },
    {
      headerName: "Tags",
      field: "tags",
      editable: true,
      cellRendererFramework: TagComponent,
      //cellEditorPopup: true,
      cellEditorFramework: TagEditorCell,
      cellEditorParams: (params) => {
        params.allTags = this.allTags
        return params;
      },
      width: 400
    },
    {
      headerName: "Teams",
      field: "assignedTeamIds",
      editable: true,
      cellRendererFramework: TeamCellComponent,
      cellRendererParams: (params) => {
        params.teams = this.teams;
        return params;
      },
      cellEditorFramework: TeamSelectMultipleEditorCell,
      cellEditorParams: (params) => {
        params.teams = this.teams;
        return params;
      },
      width: 400
    },
    {
      headerName: "SOPs",
      field: "description",
      cellRendererFramework: TemplateStepMoreComponent,
      cellRendererParams: (params) => {
        params.cloudFunc = this.cloudAttachments.bind(this);
        params.attachmentsFunc = this.attachments.bind(this);
        params.notesFunc = this.notes.bind(this);
        //params.deleteFunc = this.deleteClick.bind(this);
        return params;
      },
    },
    {
      headerName: "More Actions",
      field: "description",
      cellRendererFramework: IconMenuComponent,
      cellRendererParams: (params) => {
        params.icon = 'more_vert';
        params.badge = 0;
        let menuItems: viewmodels.MenuItem[] = [
          {
            icon: 'add_task',
            label: 'Add to Task Library',
            action: this.generateTemplatedTaskClick.bind(this, params.data)
          },
          {
            icon: 'delete',
            label: 'Delete',
            action: this.deleteClick.bind(this, params.data)
          }
        ];
        params.menuItems = menuItems;
        return params;
      },
    },
  ];
  columnDefs: Array<ColDef> = [];

  constructor(
    private deviceService: DeviceDetectorService
    , private dialog: MatDialog
    , private helperService: services.HelperService
    , private templateService: services.TemplateService
    , private templateStepListHelperService: services.TemplateStepListHelperService
    , private teamService: services.TeamService
  ) {

    this.gridOptions = <GridOptions>{
      context: {
        componentParent: this
      },
      //columnDefs: [],
      onGridReady: () => {
        this.resizeColumns();
      },
      //rowSelection: 'multiple',
      //onCellDoubleClicked: this.selectedCell.bind(this),
      rowHeight: 35,
      defaultColDef: {
        sortable: false, resizable: true,
      },
      singleClickEdit: true,
      //stopEditingWhenGridLosesFocus: true,
      enterMovesDown: true,
      enterMovesDownAfterEdit: true,
      onCellValueChanged: (event: CellValueChangedEvent) => {
        this.onCellValueChanged(event);
      }
    };
  }

  ngOnInit(): void {
    this.columnDefs = this.possibleColumns.filter(i => i.field != 'dayOfWeek');
    this.getTeams();
  }

  ngAfterViewInit(): void {
    this.resizeColumns();
    this.listenToColChanges()
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  resizeColumns() {
    //this.gridOptions.columnApi.autoSizeColumns(["description", "tags"])
    if (!this.deviceService.isMobile() && this.gridOptions.api != null) {
      this.gridOptions.api.sizeColumnsToFit();
    }
  }

  async getTeams() {
    let teams: models.Team[] = [];
    let teamResponse = await (await this.teamService.getAll().get()).toPromise()
    teamResponse.docs.forEach(doc => {
      let team: models.Team = doc.data();
      team.uid = doc.id;
      teams.push(team);
    })
    this.teams = teams;
    this.loadedTeams = true;
  }

  notes(step: models.Step) {
    let notesDialog = this.dialog.open(NotesComponent, {
      width: '50%',
      data: {
        list: step.notes,
        header: 'Event Notes',
        placeholder: 'Note'
      }
    });

    notesDialog.afterClosed().subscribe(data => {
      if (data != null) {
        step.notes = data;
        this.gridOptions.api.redrawRows();
      }
    })
  }

  cloudAttachments(step: models.Step) {
    let notesDialog = this.dialog.open(NotesComponent, {
      width: '50%',
      data: {
        list: step.cloudAttachments,
        header: 'Event Cloud Attachments',
        placeholder: 'URL'
      }
    });

    notesDialog.afterClosed().subscribe(data => {
      if (data != null) {
        step.cloudAttachments = data;
        this.gridOptions.api.redrawRows();
      }
    })
  }

  attachments(step: models.Step) {
    let notesDialog = this.dialog.open(AttachmentsSelectDialogComponent, { data: { selected: step.attachments } });

    notesDialog.afterClosed().subscribe(data => {
      if (data) {
        step.attachments = data;
        this.gridOptions.api.redrawRows();
      }
    })
  }

  deleteClick(step: models.Step) {
    let steps = this.helperService.deepCopy(this.steps);
    let found = steps.find(i => i.uid == step.uid);
    let index = steps.indexOf(found);
    steps.splice(index, 1);
    this.steps = steps;
  }

  generateTemplatedTaskClick(step: models.Step) {
    let dialog = this.dialog.open(TemplatedTaskCreateFromStepDialogComponent, { data: { step: step } });

    // dialog.afterClosed().subscribe(data => {
    //   if (data) {
    //     step.attachments = data;
    //     this.gridOptions.api.redrawRows();
    //   }
    // })
  }

  onCellValueChanged(event: CellValueChangedEvent) {
    const colId = event.column.getId();
    if (colId === "day") {
      //this.recalcDayTotals.next();
      this.replant.next();
    }
    if (colId === 'dayTotal') {
      event.data.day = this.templateService.recalcDay(this.template, this.phase, event.data);
      this.replant.next();
    }
  }

  listenToColChanges() {
    this.sub = this.templateStepListHelperService.sub.subscribe(async data => {
      if (data == null) {
        //remove column from grids
        this.columnDefs = []
        await this.helperService.wait(10);
        this.columnDefs = this.possibleColumns.filter(i => i.field != 'dayOfWeek');
      } else {
        //add column to grids
        this.columnDefs = []
        await this.helperService.wait(10);
        this.columnDefs = this.possibleColumns;
      }

      await this.helperService.wait(10);
      this.resizeColumns();
    })
  }

  dataCollectionDefinitionClick(step: models.Step) {
    const doc = document.documentElement;
    const top = (window.scrollX || doc.scrollTop) - (doc.clientTop || 0);
    const left = (window.scrollY || doc.scrollLeft) - (doc.clientLeft || 0);
    if(top != 0 || left != 0){
      window.scrollTo(0, 0);
    }
    let dialog = this.dialog.open(DataCollectionDefinitionsDialogComponent, {
      data: {
        dataCollectionDefinitions: step.dataCollectionDefinitions || []
      }
    });

    dialog.afterClosed().subscribe(data => {
      if (data) {
        step.dataCollectionDefinitions = data;
        this.gridOptions.api.redrawRows();
      }
      if(top != 0 || left != 0){
        window.scrollTo({ top: top, left: left, behavior: 'auto' });
      }
    })
  }
}
