<h2 id="sensor_add_title" mat-dialog-title>
    <span style="display: inline-block">Save {{step.description}} to Task Library</span>
  </h2>
  <mat-dialog-content style="min-width: 30vw;">
      <mat-form-field class="example-full-width" appearance="outline">
        <mat-label>Task Description</mat-label>
        <input matInput [(ngModel)]="name"/>
      </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button (click)="cancel()">Cancel</button>
    <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
    <button mat-button (click)="save()" [disabled]="saving">
      <i class="fa fa-spinner fa-spin" *ngIf="saving"></i>
      <span *ngIf="!saving">Save</span>
    </button>
  </mat-dialog-actions>
  