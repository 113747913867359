<h2 mat-dialog-title>Bulk Edit Events</h2>
<mat-dialog-content>
  <div class="row">
    <div class="col-12">
        Complete Status:
        <mat-button-toggle-group [(ngModel)]="completeStatus">
          <mat-button-toggle value="no_change">No Change</mat-button-toggle>
          <mat-button-toggle value="complete">Complete</mat-button-toggle>
          <mat-button-toggle value="incomplete">Incomplete</mat-button-toggle>
        </mat-button-toggle-group>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-6">
        <app-team-select-multiple
            [(teamIds)]="selectedTeamIds">
        </app-team-select-multiple>
        <mat-checkbox [(ngModel)]="removeAllOtherTeams">Remove All Other Teams</mat-checkbox>
    </div>
    <div class="col-sm-6">
        <app-user-select-multiple
            [(userIds)]="selectedUserIds">
        </app-user-select-multiple>
        <mat-checkbox [(ngModel)]="removeAllOtherUsers">Remove All Other Users</mat-checkbox>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-icon-button [disabled]="saving || deleting" (click)="deleteClick()">
        <mat-icon *ngIf="!deleting">delete</mat-icon>
        <mat-spinner *ngIf="deleting" diameter="20"></mat-spinner>
    </button>
    <button mat-button [disabled]="saving || deleting" mat-dialog-close>Cancel</button>
    <button mat-button [disabled]="saving || deleting" (click)="saveClick()">
        <span *ngIf="!saving">Save</span>
        <mat-spinner *ngIf="saving" diameter="20"></mat-spinner>
    </button>
</mat-dialog-actions>
