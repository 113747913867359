
export interface DataCollectionDefinitionUnit {
    id: string;
    display: string;
    suffix: string;
    inputType: string;
    sumMultiple: boolean;
}

export enum DataCollectionDefinitionUnitInputType {
    Number = 'number',
    Text = 'text',
    Date = 'date',
    Time = 'time'
}

export class DataCollectionDefinitionUnits {
    list: DataCollectionDefinitionUnit[] = [
        {
            id: 'fahrenheit',
            display: 'Fahrenheit',
            suffix: '°F',
            inputType: DataCollectionDefinitionUnitInputType.Number,
            sumMultiple: false,
        },
        {
            id: 'celsius',
            display: 'Celsius',
            suffix: '°C',
            inputType: DataCollectionDefinitionUnitInputType.Number,
            sumMultiple: false,
        },
        {
            id: 'percentage',
            display: 'Percentage',
            suffix: '%',
            inputType: DataCollectionDefinitionUnitInputType.Number,
            sumMultiple: false,
        },
        {
            id: 'number',
            display: 'Number',
            suffix: null,
            inputType: DataCollectionDefinitionUnitInputType.Number,
            sumMultiple: true,
        },
        {
            id: 'text',
            display: 'Text',
            suffix: null,
            inputType: DataCollectionDefinitionUnitInputType.Text,
            sumMultiple: false,
        },
        {
            id: 'date',
            display: 'Date',
            suffix: null,
            inputType: DataCollectionDefinitionUnitInputType.Date,
            sumMultiple: false,
        },
        {
            id: 'time-of-day',
            display: 'Time of Day',
            suffix: null,
            inputType: DataCollectionDefinitionUnitInputType.Time,
            sumMultiple: false,
        },
        {
            id: 'duration-min',
            display: 'Duration',
            suffix: 'mins',
            inputType: DataCollectionDefinitionUnitInputType.Number,
            sumMultiple: true,
        },
        {
            id: 'duration-hour',
            display: 'Duration',
            suffix: 'hours',
            inputType: DataCollectionDefinitionUnitInputType.Number,
            sumMultiple: true,
        },
        {
            id: 'weight-lbs',
            display: 'Weight',
            suffix: 'lbs',
            inputType: DataCollectionDefinitionUnitInputType.Number,
            sumMultiple: true,
        },
        {
            id: 'weight-g',
            display: 'Weight',
            suffix: 'g',
            inputType: DataCollectionDefinitionUnitInputType.Number,
            sumMultiple: true,
        },
        {
            id: 'pressure',
            display: 'Pressure',
            suffix: 'psi',
            inputType: DataCollectionDefinitionUnitInputType.Number,
            sumMultiple: false,
        },
        {
            id: 'ec',
            display: 'Electrical Conductivity',
            suffix: 'EC',
            inputType: DataCollectionDefinitionUnitInputType.Number,
            sumMultiple: false,
        },
        {
            id: 'ppm',
            display: 'Parts per million',
            suffix: 'ppm',
            inputType: DataCollectionDefinitionUnitInputType.Number,
            sumMultiple: false,
        },
        {
            id: 'ph',
            display: 'pH',
            suffix: 'pH',
            inputType: DataCollectionDefinitionUnitInputType.Number,
            sumMultiple: false,
        },
        {
            id: 'kpa',
            display: 'Pressure',
            suffix: 'kPa',
            inputType: DataCollectionDefinitionUnitInputType.Number,
            sumMultiple: false,
        },
    ]
}