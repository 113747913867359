import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-event-bulk-edit-assign-dialog',
  templateUrl: './event-bulk-edit-assign-dialog.component.html',
  styleUrls: ['./event-bulk-edit-assign-dialog.component.scss']
})
export class EventBulkEditAssignDialogComponent implements OnInit {

  selectedUserIds: Array<string> = [];
  selectedTeamIds: Array<string> = [];

  keepAllOtherUsers: boolean = false;
  keepAllOtherTeams: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<EventBulkEditAssignDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
  }

  okClick() {
    this.dialogRef.close({
      save: true,
      selectedUserIds: this.selectedUserIds,
      selectedTeamIds: this.selectedTeamIds,
      keepAllOtherUsers: this.keepAllOtherUsers,
      keepAllOtherTeams: this.keepAllOtherTeams
    });
  }

}
